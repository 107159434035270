header{
	position: fixed;
	top: 0;
	left: 0;
	height: 11.511vw;
	z-index: 20;
	width: 100%;
	transition: 0.4s;
	&.menu-active{
		.corps{

			.header-content{

				&#logo{
					@media screen and (max-width: 991px){
						display: none;
					}
					a{

						img{
							margin: 0 auto;
						}
					}
				}
			}
		}
	}
	@media screen and (max-width: 991px){
		height: 80px;
	}
	@media screen and (max-width: 767px){
		background: #1e1e1e;
		height: 70px;
	}
	@media screen and (max-width: 580px){
		height: 60px;
	}
	@media screen and (max-width: 420px){
		height: 50px;
	}
	&.head-is-Scroll{
		background-color: #1e1e1e;
		height: 5vw;
		.corps{

			.header-content{
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);
				&#logo{
					height: 3vw;
					@media screen and (max-width: 991px){
						height: 50px;
					}
					@media screen and (max-width: 767px){
						height: 40px;
					}
					@media screen and (max-width: 580px){
						height: 30px;
					}
					@media screen and (max-width: 420px){
						height: 25px;
					}
				}
			}
		}
		@media screen and (max-width: 991px){
			height: 70px;
		}
		@media screen and (max-width: 767px){
			background: #1e1e1e;
			height: 60px;
		}
		@media screen and (max-width: 580px){
			height: 50px;
		}
		@media screen and (max-width: 420px){
			height: 50px;
		}
	}
	.corps{

		.header-content{
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			&#logo{
				left: 0;
				height: 4.74vw;
				z-index: 1;
				transition: 0.4s;
				@media screen and (max-width: 991px){
					height: 60px;
				}
				@media screen and (max-width: 767px){
					height: 50px;
				}
				@media screen and (max-width: 580px){
					height: 40px;
				}
				@media screen and (max-width: 420px){
					height: 30px;
				}
				a{
					display: block;
					width: 100%;
					height: 100%;
					img{
						display: block;
						width: auto;
						height: 100%;
						transition: 0.4s;
					}
				}
			}
			&#nav{
				right: 0;
				@media screen and (max-width: 991px){
					width: 100%;
					z-index:0;
					height: 100%;
				}
				@media screen and (max-width: 767px){

				}
				#navHam{
					position: absolute;
					width: 25px;
					height: 20px;
					top: 50%;
					right: 0;
					transform: translate(0, -50%);
					display: none;
					cursor: pointer;
					@media screen and (max-width: 991px){
						display: block;
					}
					&.active{
						span{

							&:nth-child(1){
								top: 50%;
								transform: translate(0, -50%) rotate(-45deg);
							}
							&:nth-child(2){
								width: 0;
							}
							&:nth-child(3){
								bottom: 50%;
								transform: translate(0, 50%) rotate(45deg);
							}
						}
					}
					span{
						background: #fff;
						position: absolute;
						display: block;
						left: 0;
						width: 100%;
						height: 2px;
						transition: 0.4s;

						&:nth-child(1){
							top: 0;
						}
						&:nth-child(2){
							top: 50%;
							transform: translate(0, -50%);
						}
						&:nth-child(3){
							bottom: 0;
						}
					}
				}

				ul{
					display: inline-flex;
					@media screen and (min-width: 992px){
						display: inline-flex !important;
					}
					#wrap-menu{
						display: inline-flex;
						@media screen and (max-width: 991px){
							display: table;
							position: absolute;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
						}
					}
					&.active{
						display: inline-flex;
					}
					li{
						margin: 0 5.5vw 0 0;
						&.current-menu-item{
							a{
								position: relative;
								display: table;
								@media screen and (max-width: 991px){
									color: $vert-p !important;
								}
								&:after{
									background: $vert-p;
									position: absolute;
									display: block;
									content: "";
									width: 30px;
									height: 5px;
									left: 0;
									bottom: -10px;
									transition: 0.4s;
									@media screen and (max-width: 991px){
										display: none !important;
									}
								}
							}
						}
						&:last-child{
							margin: 0;

							a{
								color: $vert-f;
								font-weight: 800;
							}
						}

						a{
							position: relative;
							display: table;
							color: #fefefe;
							font-size: 1.1vw;
							font-family: $barlow;
							transition: 0.4s;
							&:after{
								background: $vert-p;
								position: absolute;
								display: block;
								content: "";
								width: 0px;
								height: 5px;
								left: 0;
								bottom: -10px;
								transition: 0.4s;
								@media screen and (max-width: 991px){
									bottom: 0;
									left: 50%;
									transform: translate(-50%, 0);
								}
							}
							&:hover{
								&:after{
									width: 30px;
								}
							}
							@media screen and (max-width: 1175px){
								font-size: 14px !important;
							}
							@media screen and (max-width: 991px){
								font-size: 25px !important;
							}
						}
					}
					@media screen and (max-width: 991px){
						background: #1e1e1e;
						position: absolute;
						display: none;
						width: calc(100% + 188px);
						left: 0;
						&.active{
							display: block;
						}
						li{
							text-align: center;
							margin: 0;
							height: calc(100% / 5);
							position: relative;
							a{
								display: block;
								width: 100%;
								padding: 10px 0;
								text-align: center;
							}
						}
					}
					@media screen and (max-width: 767px){
						background: #1e1e1e;
						position: absolute;
						display: none;
						width: calc(100% + 188px);
						left: 0;
						&.active{
							display: block;
						}
						li{
							text-align: center;
							margin: 0;
							height: calc(100% / 5);
							position: relative;
							a{
								display: block;
								width: 100%;
								padding: 10px 0;
								text-align: center;
							}
						}
					}
					@media screen and (max-width: 480px){
						background: #1e1e1e;
						position: absolute;
						display: none;
						width: calc(100% + 188px);
						left: 0;
						&.active{
							display: block;
						}
						li{
							text-align: center;
							margin: 0;
							height: calc(100% / 5);
							position: relative;
							a{
								display: block;
								width: 100%;
								padding: 10px 0;
								text-align: center;
							}
						}
					}
				}
			}
		}
	}
}