#intro-rea-sing{
	z-index: 2;
	@media screen and (max-width: 991px){
		margin: 8vw 0 0 0;
	}
	#support-sing-rea-intro{
		height: 2.3vw;
	}
	.corps{
		.contenu{
			position: absolute;
			top: 2.8vw;
			z-index: 9;
			width: 100%;
			@media screen and (max-width: 767px){
				top: initial;
				height: 100%;
				padding: 40px;
			}
			h1{
				color: #fff;
				font-size: 5.55vw;
				line-height: 5.5vw;
				font-weight: 900;
				margin: 0 0 1.8vw 0px;
				max-width: 40.65vw;
				@media screen and (max-width: 767px){
					font-size: 53px;
					line-height: 51px;
					max-width: 80%;
				}
				@media screen and (max-width: 580px){
					max-width: 90%;
					font-size: 48px;
					line-height: 46px;
				}
			}
			.num-project{
				color: #fff;
				display: block;
				font-size: 2.5vw;
				font-weight: 100;
				line-height: 100%;
				margin: 0 0 1vw 0;
				@media screen and (max-width: 767px){
					font-size: 42px;
				}
				@media screen and (max-width: 767px){
					font-size: 35px;
				}
			}
			.type{
				position: relative;
				color: $vert-p;
				font-size: 1.05vw;
				font-family: $barlow;
				font-weight: 500;
				text-transform: uppercase;
				letter-spacing: 0.2010em;
				margin: 0 0 1.4vw 0;
				@media screen and (max-width: 991px){
					font-size: 2vw;
				}
				@media screen and (max-width: 767px){
					font-size: 15px;
				}
				@media screen and (max-width: 767px){
					font-size: 13px;
				}

				.bar{

					background: $vert-p;
					position: absolute;
					display: block;
					content: "";
					height: 2px;
					top: 50%;
					left: 0;
					transform: translate(0, -50%);
				}
			}
			.bouton{
				margin: 25px 0 0 0;
				@media screen and (max-width: 991px){
					margin: 20px 0 0 0;
				}
				a{
					display: table;
					border: 2px solid $vert-p;
					font-size: 16px;
					letter-spacing: 0.2010em;
					text-transform: uppercase;
					color: #fff;
					padding: 25px 20px;
					float: left;
					@media screen and (max-width: 991px){
						padding: 20px 15px;
						font-size: 14px;
					}
					@media screen and (max-width: 767px){
						float: initial;
					}
					@media screen and (max-width: 580px){
						font-size: 12px;
						padding: 10px 5px;
					}
					&:nth-child(1){
						background: $vert-p;
					}
					&:nth-child(2){
						background: rgba(0, 0, 0, 0.6);
						margin: 0 0 0 20px;
						@media screen and (max-width: 767px){
							margin: 20px 0 0 0;
						}
					}
				}
			}
		}
		#boite-blanc{
			background: #fff;
			position: absolute;
			bottom: -2.3vw;
			height: 10.0523vw;
			z-index: 9;
			@media screen and (max-width: 767px){
				height: auto;
				bottom: initial;
				position: relative;
				padding: 30px 0;
			}

			.part-boite{
				position: absolute;
				top: 50%;
				transform: translate(0, -50%);
				@media screen and (max-width: 767px){
					position: relative;
					float: left;
					left: initial !important;
					right: initial !important;
					top: initial !important;
					transform: initial !important;
					text-align: center;
				}
				@media screen and (max-width: 420px){
					width: 100% !important;
					& + .part-boite{
						margin: 30px 0 0 0 !important;
					}
				}
				&#timing{
					left: 6.5vw;
					@media screen and (max-width: 767px){
						width: 50%;
					}
					span{

						&:before{
							background-image: url(../images/svg/time.svg);
							width: 2.084vw;
							height: 2.084vw;
							@media screen and (max-width: 767px){
								width: 32px;
								height: 32px;
							}
						}
					}
				}
				&#emplacement{
					left: 50%;
					transform: translate(-50%, -50%);
					@media screen and (max-width: 767px){
						width: 50%;
					}
					span{

						&:before{
							background-image: url(../images/svg/emplacement.svg);
							width: 2.032vw;
							height: 2.813vw;
							@media screen and (max-width: 767px){
								width: 32px;
								height: 32px;
							}
						}
					}
				}
				&#num-model{
					right: 4.3vw;
					@media screen and (max-width: 767px){
						width: 100%;
						margin: 30px 0 0 0;
					}
					span{

						&:before{
							background-image: url(../images/svg/modeles.svg);
							width: 4.7vw;
							height: 1.9vw;
							@media screen and (max-width: 767px){
								width: 59px;
								height: 37px;
							}
						}
						span{
							&:before{
								display: none;
							}
						}
					}
				}
				span{
					font-size: 1.4vw;
					letter-spacing: 0.05em;
					@media screen and (max-width: 767px){
						font-size: 19px;
					}

					&:before{
						background-size: 100% 100%;
						background-repeat: no-repeat;
						background-position: center center;
						position: relative;
						display: block;
						content: "";
						margin: 0 auto 0.4vw auto;
					}
				}
			}
		}
		#cc-reaSing-carou{
			float: right;
			@media screen and (max-width: 991px){
				width: 90%;
			}
			@media screen and (max-width: 767px){
				height: 400px !important;
				width: 100%;
			}
			.carousel-inner{
				background: #1e1e1e;
				width: 100%;
				height: 100%;
				@media screen and (max-width: 991px){
					width: 100%;
				}
				.item{
					background-size: cover;
					background-repeat: no-repeat;
					width: 64.219vw;
					height: 100%;
					opacity: 0.6;
					@media screen and (max-width: 991px){
						width: 100%;
					}
				}
			}
			.cc-carou-control{
				background: #000;
				position: absolute;
				right: 0;
				bottom: 0;
				width: 7.917vw;
				height: 14.4276vw;
				z-index: 10;
				@media screen and (max-width: 767px){
					width: 61px;
					height: 111px;
				}
				a{
					position: initial;
					top: initial;
					left: initial;
					right: initial;
					bottom: initial;
					display: inline-block;
					width: 100%;
					height: 50%;
					opacity: 1;
					&:first-child{
						.glyphicon{
							&:after{

							}
							&:hover{
								&:after{
									left: 60%;
								}
							}
						}
					}
					&:last-child{
						.glyphicon{
							&:after{

								transform: translate(-50%, -50%) rotate(180deg);
							}
							&:hover{
								&:after{
									left: 40%;
								}
							}
						}
					}
					.glyphicon{
						background: #000;
						position: relative;
						display: block;
						width: 100%;
						height: 100%;
						top: initial;
						left: initial;
						right: initial;
						bottom: initial;
						margin: 0 !important;
						&:before{
							display: none;
						}
						&:after{
							background-image: url(../images/svg/arrow-slide.svg);
							background-size: 100% 100%;
							background-position: center center;
							position: absolute;
							display: block;
							content: "";
							top: 50%;
							left: 50%;
							width: 3.698vw;
							height: 1.875vw;
							transform: translate(-50%, -50%);
							transition: 0.4s;
							@media screen and (max-width: 767px){
								width: 28px;
								height: 14px;
							}
						}
					}
					.sr-only{
						display: none;
					}
				}
			}
		}
	}
}

#description-rea-single{
	padding: 10.9vw 0 8.3vw 0;
	z-index: 2;
	@media screen and (max-width: 767px){
		padding: 70px 0 60px 0;
	}
	@media screen and (max-width: 420px){
		
	}

	.corps{

		p{
			color: $vert-p;
			font-size: 2.45vw;
			font-weight: 500;
			letter-spacing: 0.05em;
			@media screen and (max-width: 991px){
				font-size: 23px;
				line-height: 30px;
			}
			@media screen and (max-width: 420px){
				font-size: 20px;
				text-align: center;
				line-height: 28px;
			}
		}
	}
}

#modele{
	z-index: 2;
	.corps{

		h3{

			span{
				font-weight: 100;
				font-size: 2.15vw;
				position: absolute;
				margin: 0 0 0 15px;
				@media screen and (max-width: 991px){
					font-size: 35px;
				}
				@media screen and (max-width: 580px){
					font-size: 30px;
				}
			}
		}
	}
	#modal-liste{
		margin: 4.2vw 0 60px 0;
		@media screen and (max-width: 991px){
			margin: 60px 0;
		}
		@media screen and (max-width: 420px){
			margin: 45px 0 60px 0;
		}
		.modal-unit{
			position: relative;
			overflow: hidden;
			.contenu{
				background: #0e0e0e;
				position: absolute;
				width: 100%;
				height: 100%;
				left: 0;
				bottom: -100%;
				opacity: 0;
				transition-property: bottom, opacity;
				transition-duration: 0.4s, 0.4s;
				@media screen and (max-width: 420px){
					display: none;
				}
				.contenu-int{
					padding: 1.9vw 2.344vw;
					@media screen and (max-width: 767px){
						padding: 4vw;
					}
					h4{
						font-size: 1.4vw;
						font-weight: 600;
						letter-spacing: 0.05em;
						text-transform: uppercase;
						color: #fff;
						margin: 0 0 1vw 0;
						@media screen and (max-width: 1175px){
							font-size: 1.6vw;
							margin: 0 0 1.2vw 0;
						}
						@media screen and (max-width: 991px){
							font-size: 1.9vw;
							margin: 0 0 1.5vw 0;
						}
						@media screen and (max-width: 767px){
							font-size: 2.2vw;
							margin: 0 0 1.8vw 0;
						}
					}
					p{
						font-size: 1.1vw;
						letter-spacing: 0.05em;
						color: #fff;
						line-height: 1.2vw;
						@media screen and (max-width: 1175px){
							font-size: 1.4vw;
							line-height: 1.4vw;
						}
						@media screen and (max-width: 991px){
							font-size: 1.6vw;
							line-height: 1.8vw;
						}
						@media screen and (max-width: 767px){
							font-size: 1.9vw;
							line-height: 2.2vw;
						}
					}
					span{
						display: table;
						font-size: 0.75vw;
						letter-spacing: 0.2010em;
						color: $vert-p;
						text-transform: uppercase;
						font-weight: 900;
						padding: 0.8vw;
						margin: 1vw 0 0 0;
						border: 2px solid $vert-p;
						@media screen and (max-width: 1175px){
							font-size: 1vw;
							margin: 1.2vw 0 0 0;
							padding: 1.2vw;
						}
						@media screen and (max-width: 991px){
							font-size: 1.2vw;
							margin: 1.4vw 0 0 0;
							padding: 1.2vw;
						}
						@media screen and (max-width: 767px){
							font-size: 1.4vw;
							margin: 1.6vw 0 0 0;
							padding: 1.4vw;
						}
					}
				}
			}
			&:hover{
				.contenu{
					bottom: 0;
					opacity: 1;
					.contenu-int{


					}
				}
			}
		}
		.modal-contenu{
			background: #000;
			position: fixed;
			display: none;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 21;
			padding: 125px 0 0 0;
			overflow: visible !important;
			overflow-x: hidden !important;
			&.clic{
				display: block;
			}
			.close-modal{
				background-image: url(../images/svg/close.svg);
				background-size: 100% 100%;
				background-position: center center;
				position: absolute;
				display: block;
				width: 36px;
				height: 36px;
				right: 0;
				cursor: pointer;
				z-index: 22;
			}
			.recall-page{
				position: absolute;
				@media screen and (max-width: 991px){
					display: none;
				}
				h2{
					font-size: 37px;
				}
			}
			.cc-carousel-in-modal{
				height: 100%;
				.carousel-control{
					height: calc(39.844vw + 8.4vw + 8.4vw);
					opacity: 1;
					background: none !important;
					.glyphicon-chevron-right{
						&:before{
							display: none;
						}
					}
					.glyphicon-chevron-left{
						&:before{
							display: none;
						}
					}

					&.right{
						right: -5.209vw;
						transition: 0.4s;
						&:hover{
							right: 0;
						}
						&:before{
							background-image: url(../images/svg/arrow-slick.svg);
							background-repeat: no-repeat;
						    background-size: 49px 25px;
						    background-position: 50%;
						    background-color: #f1f1f1;
						    position: absolute;
						    display: block;
						    content: "";
						    top: 50%;
						    left: 50%;
						    width: 7.865vw;
						    height: 7.24vw;
						    transform: translate(-50%,-50%);
						    filter: invert(100%);
						    @media screen and (max-width: 1175px){
						    	width: 93px;
						    	height: 86px;
						    }
						}
					}
					&.left{
						left: -5.209vw;
						transition: 0.4s;
						&:hover{
							left: 0;
						}
						&:before{
							background-image: url(../images/svg/arrow-slick.svg);
							background-repeat: no-repeat;
						    background-size: 49px 25px;
						    background-position: 50%;
						    background-color: #f1f1f1;
						    position: absolute;
						    display: block;
						    content: "";
						    top: 50%;
						    left: 50%;
						    width: 7.865vw;
						    height: 7.24vw;
						    transform: translate(-50%,-50%) rotate(180deg);
						    filter: invert(100%);
						    @media screen and (max-width: 1175px){
						    	width: 93px;
						    	height: 86px;
						    }
						}
					}
					@media screen and (max-width: 580px){
						height: calc(300px + 8.4vw);
					}
				}
				.carousel-indicators{
					
					position: relative;
					top: initial;
					left: initial;
					right: initial;
					bottom: initial;
					margin: 3.1vw 0 0 0;
					text-align: initial;
					width: 100%;
					li{
						background: none;
						display: block;
						margin: 0 0 3.1vw 0;
						float: left;
						height: 8.75vw;
						@media screen and (max-width: 767px){
							width: 100% !important;
							height: 250px !important;
						}
						@media screen and (max-width: 580px){
							width: 100% !important;
							height: 200px !important;
						}
						.li-int{
							background-repeat: no-repeat;
							background-size: cover;
							display: block;
							width: calc(100% - 3.4vw);
							height: 100%;
							margin: 0 auto;
						}
						&.active{
							.li-int{
								background-color: #fff;
								background-repeat: no-repeat;
								background-size: cover;
								display: block;
								height: 100%;
							}
						}
					}
				}
				.carousel-inner{
					height: calc(39.844vw + 8.4vw);
					padding: 8.4vw 0 0 0;
					//margin: 5.2vw 0 0 0;
					@media screen and (max-width: 580px){
						height: 300px;
						padding: 70px 0 0 0;
					}
					.item{
						background-repeat: no-repeat;
						background-size: cover;
						position: relative;
						height: 100%;
						
						.cc-caption{
							position: absolute;
							top: -7.5vw;
							right: 16vw;
							@media screen and (max-width: 580px){
								right: initial;
								top: -70px;
							}
							span{
								color: #fff;
								font-size: 1.4vw;
								font-weight: 500;
								text-transform: uppercase;
								letter-spacing: 0.05em;
								@media screen and (max-width: 991px){
									font-size: 16px;
								}
							}
						}
					}
				}
			}
		}
		.slick-arrow{
			background: $vert-p;
			width: 86px;
			height: 67px;
			z-index: 9;
			&.slick-disabled{
				display: none !important;
			}
			@media screen and (max-width: 580px){
				width: 66px;
				height: 47px;
			}
			&:before{
				display: none !important;
			}
			&:after{
				background-image: url(../images/svg/arrow-slick.svg);
				background-size: 100% 100%;
				background-position: center center;
				position: absolute;
				display: block;
				content: "";
				top: 50%;
				left: 50%;
				width: 49px;
				height: 25px;
				transform: translate(-50%, -50%);
				@media screen and (max-width: 580px){
					width: 35px;
					height: 10px;
				}
			}
			&.slick-prev{
				left: 0;
				transition: 0.4s;
				&:hover{
					left: 10px;
				}
				&:after{
					transform: translate(-50%, -50%) rotate(180deg);
				}
			}
			&.slick-next{
				right: 0;
				transition: 0.4s;
				&:hover{
					right: 10px;
				}
			}
		}
		.slick-slider{

			height: 14.063vw;
			@media screen and (max-width: 1175px){
				height: 19.063vw;
			}
			@media screen and (max-width: 991px){
				height: 24.063vw;
			}
			@media screen and (max-width: 767px){
				height: 29.063vw;
			}
			@media screen and (max-width: 480px){
				height: 34.063vw;
			}
			.slick-list{
				height: 100%;
				margin: 0 -0.5vw;
				.slick-track{
					height: 100%;
					.slick-slide{
						background-color: #fff;
						background-size: cover;
						height: 14.063vw;
						margin: 0 0.5vw;
						opacity: 0.4;
						@media screen and (max-width: 1175px){
							height: 19.063vw;
						}
						@media screen and (max-width: 991px){
							height: 24.063vw;
						}
						@media screen and (max-width: 767px){
							height: 29.063vw;
						}
						@media screen and (max-width: 480px){
							height: 34.063vw;
						}
						&.with-post{
							cursor: pointer;
							opacity: 1;
						}
					}
				}
			}
		}
	}
}

#list-num-rea{
	background: #000;
	height: 9.896vw;
	z-index: 2;
	@media screen and (max-width: 1175px){
		height: 117px;
	}
	.corps{

		ul{
			position: absolute;
			display: table;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			li{
				float: left;
				margin: 0 0 0 12px;
				&:first-child{
					margin: 0;
				}
				&.active{

					a{
						border: 2px solid #fff;
						border-radius: 4.4798vw;
						@media screen and (max-width: 1175px){
							border-radius: 52px;
						}
					}
				}

				a{
					color: #fff;
					display: block;
					font-size: 1.75vw;
					font-weight: 100;
					width: 4.4798vw;
					height: 4.4798vw;
					text-align: center;
					line-height: 4.3vw;
					@media screen and (max-width: 1175px){
						width: 52px;
						height: 52px;
						font-size: 20px;
						line-height: 50px;
					}
				}
			}
		}
	}
}