html {
	font-size:10px;
	overflow-x:hidden;
	&.menu-active{
		overflow: hidden;
	}
}

body {
	background: #1e1e1e;
	margin:0px;
	font-family: $barlow;
	&.menu-active{
		visibility: hidden;
		overflow-y: hidden;
		header{
			visibility: visible;
		}
	}
}

* {
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
}

a {
	text-decoration: none !important;
}
p{
	margin: 0;
}

ul{
	list-style: none;
	padding: 0;
	margin: 0;
}

img {
	max-width: 100%;
}


textarea {
	resize: vertical;
}

.clear{
	clear: both;
}

section{
	display: block;
	position: relative;
	width: 100%;
	height: auto;
	clear: both;
}

.corps{
	display: block;
	position: relative;
	max-width: 79.1778vw;
	height: 100%;
	margin: 0 auto;
	clear: both;
}

// gestion des titres
h1, h2, h3, h4, h5, h6{
	margin: 0;
}

.tg{
	color: #fff;
    font-size: 4.5vw;
    font-family: Barlow,sans-serif;
    font-weight: 900;
    @media screen and (max-width: 991px){
    	font-size: 6vw;
    }
    @media screen and (max-width: 767px){
    	font-size: 7vw;
    }
    @media screen and (max-width: 480px){
    	font-size: 7.7vw;
    }
}

.tp{
	color: #fff;
    font-size: 3.15vw;
    font-family: Barlow,sans-serif;
    font-weight: 900;
    @media screen and (max-width: 991px){
    	font-size: 46px;
    }
    @media screen and (max-width: 767px){
    	
    }
    @media screen and (max-width: 580px){
    	font-size: 40px;
    }
}

// Boutons
.bou{
	display: inline-block;
    font-size: .85vw;
    font-family: Barlow,sans-serif;
    font-weight: 900;
    text-transform: uppercase;
    border: 1px solid $vert-p;
    padding: 1vw 1.1vw;
    letter-spacing: .201em;
    @media screen and (max-width: 1175px){
		font-size: 1.2vw;
	}
	@media screen and (max-width: 991px){
		font-size: 14px;
		padding: 12px 15px;
	}
	@media screen and (max-width: 767px){

	}
	@media screen and (max-width: 580px){
		font-size: 13px !important;
	}
	@media screen and (max-width: 420px){
		
	}
}

.classi-bar{
	background: $vert-f;
	position: absolute;
	width: 0.7292vw;
	height: 15.938vw;
	top: 0;
	left: 0;
	z-index: 21;
}



.grille-deco{
	background: #272829;
	position: fixed;
	display: block;
	width: 1px;
	z-index: 1;

	&.head-is-Scroll{
		top: 7vw !important;
	}
	&#g1{
		left: 0;
	}
	&#g2{
		left: 25%;
	}
	&#g3{
		left: 50%;
		transform: translate(-50%, 0);
	}
	&#g4{
		right: 25%;
	}
	&#g5{
		right: 0;
	}

}

#fb-bouton{
	background: #000;
	position: fixed;
	top: 50%;
	right: 0;
	transform: translate(51px, -50%) rotate(-90deg);
	z-index: 4;
	padding: 15px 45px 15px 15px;
	cursor: pointer;
	@media screen and (max-width: 1175px){
		
	}
	@media screen and (max-width: 991px){
		display: none !important;
	}
	span{
		color: #fff;
		float: left;
		line-height: 34px;
		font-size: 12.65px;
		font-family: $barlow;
		font-weight: 500;
		letter-spacing: 0.2010em;
		text-transform: uppercase;
		&:before{
			background-image: url(../images/svg/facebook.svg);
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: center center;
			position: relative;
			display: block;
			content: "";
			width: 19px;
			height: 34px;
			left: 20px;
			float: right;
			transform: rotate(90deg);
		}
	}
}

#fb-bouton-mobile{
	background-image: url(../images/svg/facebook.svg);
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center center;
	position: fixed;
	content: "";
	display: none;
	width: 24px;
	height: 39px;
	left: 50%;
	bottom: 50px;
	transform: translate(-50%, 0);
	z-index: 9999;
	visibility: visible !important;
}
.bouton-style-un{
	position: relative;
	transition-property: color;
	transition-duration: 0.4s;
	span{
		position: relative;
		display: block;
		text-align: center;
		z-index: 1;
	}
	&:after{
		background: #fff;
		position: absolute;
		display: block;
		content: "";
		top: 0;
		left: 50%;
		width: 100%;
		height: 0;
		opacity: 0;
		transform: translate(-50%, 0);
		transition-property: opacity, height;
  		transition-duration: 0.4s, 0.4s, 0.4s, 0.4s;
  		z-index: 0;
	}
	&:hover{
		color: $vert-p !important;
		&:after{
			height: 100%;
			opacity: 1;
		}
	}
	&.b{
		&:after{
			background: $vert-f;
		}
		&:hover{
			color: #fff !important;
			&:after{
				height: 100%;
				opacity: 1;
			}
		}
	}
	&.no-bg{
		&:after{
			background: #1e1e1e;
		}
		&:hover{
			color: $vert-p !important;
			&:after{
				height: 100%;
				opacity: 1;
			}
		}
	}
}
.bouton-style-deux{
	position: relative;
	transition-property: color;
	transition-duration: 0.4s;
	&:after{
		background: $vert-p;
		position: absolute;
		display: block;
		content: "";
		left: 50%;
		bottom: 0;
		width: 100%;
		height: 0;
		opacity: 0;
		transform: translate(-50%, 0);
		transition-property: opacity, height;
  		transition-duration: 0.4s, 0.4s, 0.4s, 0.4s;
  		z-index: -1;
	}
	&:hover{
		color: #fff !important;
		&:after{
			height: 100%;
			opacity: 1;
		}
	}
	&.b{
		&:after{
			background: #fff;
		}
		&:hover{
			color: $vert-f!important;
		}
	}
}


// LOADING
#loading{
	background: #000;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 999;
	#centre{
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		img{
			display: block;
			animation: opac 1s 1;
		}
	}
}


#logo-for-menuActive{
	position: fixed;
	display: none;
	top: 100px;
	left: 50%;
	height: 100px;
	z-index: 21;
	transform: translate(-50%, 0);
	a{
		display: block;
		height: 100%;
		img{
			display: block;
			width: auto;
			height: 100%;
		}
	}
	@media screen and (max-width: 991px){
		&.menu-active{
			display: block;
		}
	}
}

// Effects
/*.effect{
	transition: 1s;
}
.yes-is{
	transform: translate(0, 0) !important;
}*/