#sec-404{
	position: relative;
	height: 500px;
	z-index: 2;
	.contenu{
		position: absolute;
		top: 50%;
		width: 100%;
		text-align: center;
		transform: translate(0, -50%);

		h1{
			font-size: 40px;
		    font-family: barlow;
		    font-weight: 800;
		    color: #fff;
		    @media screen and (max-width: 991px){
		    	font-size: 30px;
		    	padding: 0 40px;
		    }
		     @media screen and (max-width: 580px){
		    	font-size: 25px;
		    	padding: 0 20px;
		    }
		}

		a{
			font-size: 40px;
		    font-family: barlow;
		    font-weight: 500;
		    color: $vert-p;
		    @media screen and (max-width: 991px){
		    	font-size: 30px;
		    }
		    @media screen and (max-width: 580px){
		    	font-size: 25px;
		    	padding: 0 20px;
		    }
		}
	}
}