#intro-contact{
	height: calc(31.25vw - 11.511vw);
	z-index: 3;
	@media screen and (max-width: 991px){
		height: calc(36.25vw - 115px);
	}
	@media screen and (max-width: 767px){
		height: calc(41.25vw - 70px);
	}
	@media screen and (max-width: 580px){
		height: auto;
		padding: 0 0 60px 0;
	}
	.corps{

		.contenu{
			position: absolute;
			top: calc(50% - (11.511vw / 2);
			left: 0;
			width: 100%;
			transform: translate(0, -50%);
			@media screen and (max-width: 580px){
				position: initial;
				top: initial;
				left: initial;
				transform: initial;
			}
			h1{
				color: #fff;
				font-size: 4.5vw;
				font-weight: 800;
				line-height: 5.5vw;
				float: left;
				@media screen and (max-width: 991px){
					font-size: 4.8vw;
					margin: 0 0 2vw 0;
					float: initial;
				}
				@media screen and (max-width: 767px){
					font-size: 40px;
					margin: 0 0 15px 0;
				}
				@media screen and (max-width: 580px){
					padding: 60px 0 0 0;
				}
				@media screen and (max-width: 420px){
					font-size: 30px;
				}
			}
			p{
				position: absolute;
				display: block;
				top: 50%;
				left: 35vw;
				color: #fff;
				max-width: 27.865vw;
				float: left;
				font-size: 1.3vw;
				letter-spacing: 0.05em;
				transform: translate(0, -50%);
				@media screen and (max-width: 991px){
					
				}
				@media screen and (max-width: 991px){
					left: initial;
					top: initial;
					transform: initial;
					max-width: 75%;
					position: initial;
					float: initial;
					font-size: 16px;
					line-height: 24px;
				}
				@media screen and (max-width: 580px){
					position: relative;
					width: 100%;
				}
			}
		}
		#global-infos{
			background: $vert-f;
			position: absolute;
			bottom: -3.9vw;
			height: 9.115vw;
			z-index: 1;
			@media screen and (max-width: 1175px){
				width: 476px !important;
				height: 108px;
				bottom: calc(-108px / 2);
			}
			@media screen and (max-width: 580px){
				left: 0 !important;
				width: 100% !important;
				height: auto;
				padding: 45px 0;
				position: relative;
				top: initial;
				bottom: initial;
				margin: 60px 0 0 0;
			}
			.g-info-int{
				position: absolute;
				display: inline-flex;
				top: 50%;
				left: 50%;
				width: calc(100% - 6.3vw);
				height: 2.136vw;
				transform: translate(-50%, -50%);
				@media screen and (max-width: 1175px){
					height: 108px;
				}
				@media screen and (max-width: 580px){
					position: initial;
					display: block;
					text-align: center;
					transform: initial;
					height: initial;
					width: 100%;
					top: initial;
					left: initial;
				}
				a{
					position: absolute;
					display: block;
					top: 50%;
					transform: translate(0, -50%);
					font-size: 1.4vw;
					letter-spacing: 0.05em;
					color: #333;
					@media screen and (max-width: 1175px){
						font-size: 16px;
					}
					@media screen and (max-width: 580px){
						position: initial !important;
						top: initial !important;
						transform: initial !important;
						width: 100%;
						right: initial !important;
						left: initial !important;

						& + span{
							margin: 30px 0 0 0;
						}
					}
					&:nth-child(1){
						line-height: 2.136vw;
						left: 0;
						@media screen and (max-width: 1175px){
							line-height: 25px;
						}
						&:before{
							background-image: url(../images/svg/tel-black.svg);
							background-size: 100% 100%;
							background-repeat: no-repeat;
							background-position: center center;
							position: relative;
							display: block;
							content: "";
							width: 1.511vw;
							height: 2.136vw;
							float: left;
							margin: 0 0.5vw 0 0;
							@media screen and (max-width: 1175px){
								width: 18px;
								height: 25px;
							}
							@media screen and (max-width: 580px){
								float: initial;
								margin: 0 auto 10px auto;
							}
						}
					}
					&:nth-child(2){
						line-height: 1.511vw;
						right: 0;
						@media screen and (max-width: 1175px){
							line-height: 18px;
						}
						&:before{
							background-image: url(../images/svg/courriel.svg);
							background-size: 100% 100%;
							background-repeat: no-repeat;
							background-position: center center;
							position: relative;
							display: block;
							content: "";
							width: 2.032vw;
							height: 1.511vw;
							float: left;
							margin: 0 0.5vw 0 0;
							@media screen and (max-width: 1175px){
								width: 24px;
								height: 18px;
							}
							@media screen and (max-width: 580px){
								float: initial;
								margin: 0 auto 10px auto;
							}
						}
					}
				}
			}
		}
	}
}

#contact-form{
	background-color: #fff;
	padding: 11.8vw 0 12.6vw 0;
	z-index: 2;
	.corps{
		.gform_confirmation_message{
			font-size: 23px;
		    font-family: barlow;
		    font-weight: 600;
		}
		.gform_wrapper{
			margin: 0;
			form{
				.gform_footer{
					position: absolute;
					margin: 0;
					padding: 0;
					left: 0;
					bottom: -1.5vw;
					display: inline-block;
					clear: both;
					width: 100%;
					input{
						background: $vert-p;
						position: absolute;
						left: 50%;
						display: inline-block;
					    font-size: .85vw;
					    font-family: Barlow,sans-serif;
					    font-weight: 900;
					    text-transform: uppercase;
					    border: 1px solid $vert-p;
					    padding: 1vw 1.1vw;
					    letter-spacing: .201em;
					    color: #fff;
					    @media screen and (max-width: 1175px){
					    	font-size: 13px;
					    }
					    @media screen and (max-width: 767px){
					    	left: 0;
					    	width: 100%;
					    }
					}
				}
				.gform_body{
					min-height: 10.99vw;
					ul{
						&:after{
							display: table;
							content: "";
							clear: both;
						}
						li{
							float: left;
							width: calc(50% - 10vw);
							padding: 0;
							margin: 0;
							position: relative;
							clear: both;
							@media screen and (max-width: 767px){
								width: 100%;
							}
							&.gfield_error{
								label{
									background: transparent;
									border-top: 2px solid #ff0000 !important;
									outline: 0;
									font-weight: 900;
									letter-spacing: 0.2010em;
									text-transform: uppercase;
									color:#ff0000 !important;
									width: 100%;
									font-size: .85vw;
									margin: 40px 0 0 0;
									@media screen and (max-width: 1175px){
								    	font-size: 12px;
								    }
								}
								.ginput_container{
									textarea{
										border-color: #ff0000 !important;
										&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
										  color:#ff0000;
										}
										&::-moz-placeholder { /* Firefox 19+ */
										  color: #ff0000;
										}
										&:-ms-input-placeholder { /* IE 10+ */
										  color: #ff0000;
										}
										&:-moz-placeholder { /* Firefox 18- */
										  color: #ff0000;
										}
									}
								}
							}
							&#field_1_5{
								position: absolute;
								right: 0;
								width: 50%;
								margin: 40px 0 0 0;
								.ginput_container{
									position: relative !important;
									height: auto !important;
								}
								@media screen and (max-width: 767px){
									position: initial;
									width: 100%;
								}
								label{
									display: none !important;
								}
							}
							label{
								background: transparent;
								border-top: 2px solid #000;
								outline: 0;
								font-weight: 900;
								letter-spacing: 0.2010em;
								text-transform: uppercase;
								color:#000;
								width: 100%;
								font-size: .85vw;
								margin: 40px 0 0 0;
								@media screen and (max-width: 1175px){
							    	font-size: 12px;
							    }
							}
							.ginput_container{
								margin: 0;
								position: absolute;
								top: 0;
								width: 100%;
								.instruction{
									display: none;
								}
								input{
									background: transparent;
									border: none;
									outline: 0;
									font-weight: 900;
									letter-spacing: 0.2010em;
									text-transform: uppercase;
									color:#000;
									width: 100%;
									height: 40px;
									font-size: .85vw;
									left: 0;
									top: 0;
									@media screen and (max-width: 1175px){
								    	font-size: 12px;
								    }
									&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
									  color:#000;
									}
									&::-moz-placeholder { /* Firefox 19+ */
									  color: #000;
									}
									&:-ms-input-placeholder { /* IE 10+ */
									  color: #000;
									}
									&:-moz-placeholder { /* Firefox 18- */
									  color: #000;
									}
								}
								textarea{
									background: transparent;
									border: 2px solid #000;
									outline: 0;
									font-weight: 900;
									letter-spacing: 0.2010em;
									text-transform: uppercase;
									color:#000;
									height: 205px;
									font-size: .85vw;
									width: 100%;
									padding: 20px;
									@media screen and (max-width: 1280px){
										height: 150px;
									}
									@media screen and (max-width: 1175px){
										height: 125px;
										font-size: 12px;
									}
									&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
									  color:#000;
									}
									&::-moz-placeholder { /* Firefox 19+ */
									  color: #000;
									}
									&:-ms-input-placeholder { /* IE 10+ */
									  color: #000;
									}
									&:-moz-placeholder { /* Firefox 18- */
									  color: #000;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}