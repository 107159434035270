#intro-rea{
	height: calc(36.9276vw - 11.511vw);
	@media screen and (max-width: 991px){
		height: calc(41.9276vw - 115px);
	}
	@media screen and (max-width: 767px){
		height: calc(46.9276vw - 70px);
	}
	@media screen and (max-width: 580px){
		height: auto;
		padding: 0 0 60px 0;
	}
	.corps{
 
		.contenu{
			position: absolute;
			top: calc(50% - (11.511vw / 2);
			left: 0;
			width: 100%;
			transform: translate(0, -50%);
			z-index: 2;
			@media screen and (max-width: 580px){
				position: initial;
				top: initial;
				left: initial;
				transform: initial;
			}
			h1{
				color: #fff;
				width: 75%;
				font-size: 6.3vw;
				font-weight: 800;
				line-height: 5.5vw;
				float: left;
				@media screen and (max-width: 991px){
					font-size: 6.5vw;
					margin: 0 0 2vw 0;
					float: initial;
					line-height: 32px;
				}
				@media screen and (max-width: 767px){
					font-size: 40px;
					margin: 0 0 15px 0;
				}
				@media screen and (max-width: 580px){
					padding: 60px 0 0 0;
				}
				@media screen and (max-width: 420px){
					font-size: 30px;
				}
			}
			p{
				position: absolute;
				display: block;
				top: 50%;
				left: 65%;
				color: #fff;
				width: 35%;
				float: left;
				font-size: 1.3vw;
				letter-spacing: 0.05em;
				transform: translate(0, -50%);
				@media screen and (max-width: 1200px){
					font-size: 1.3vw;

				}
				@media screen and (max-width: 991px){
					left: initial;
					top: initial;
					transform: initial;
					max-width: 75%;
					position: initial;
					float: initial;
					font-size: 16px;
					line-height: 24px;
					width: 100%;
				}
				@media screen and (max-width: 580px){
					position: relative;
					max-width: 100%;
				}
			}
		}
	}
}

#liste-rea{
	background-color: #000;
	padding: 0 0 9.5vw 0;
	.corps{
		.bou{
			background-color: $vert-p;
			color: #fff;
			z-index: 4;
			position: relative;
		}
		#rea-list{
			position: relative;
			padding: 0 0 3.65vw 0;
			z-index: 2;
			.rea-by-rea{
				float: left;
				width: 50%;
				margin: 0 0 6.3vw 0;
				@media screen and (max-width: 767px){
					width: 100%;
				}
				@media screen and (max-width: 580px){
					margin: 0 !important;
					& + .rea-by-rea{
						margin: 40px 0 0 0 !important;
					}
				}
				&:nth-child(1),
				&:nth-child(2){
					margin: -3.3vw 0 6.3vw 0;
				}
				.rea-int{
					position: relative;
					max-width: 33.073vw;
					@media screen and (max-width: 767px){
						max-width: 100%;
					}
					&:hover{
						.project-bar{
							height: 20vw;
							@media screen and (max-width: 767px){
								height: 35vw;
							}
						}
					}
					.project-bar{
						background: $vert-p;
						position: absolute;
						top: 0;
						left: 0;
						width: 16px;
						height: 11.719vw;
						z-index: 4;
						transition: 0.4s;
						@media screen and (max-width: 767px){
							height: 25vw;
						}
					}
					.img-present{
						background-color: #000;
						background-size: cover;
						background-repeat: no-repeat;
						position: relative;
						display: block;
						width: 100%;
						height: 22.448vw;
						&:after{
							background: rgba(0, 0, 0, 0.2);
							position: absolute;
							display: block;
							content: "";
							top: 0;
							left: 0;
							width: 100%;
							height: 100%;
						}
						@media screen and (max-width: 767px){
							height: 47.448vw;
						}
						span{
							background: $vert-p;
							position: absolute;
							display: table;
							font-size: 0.9vw;
							font-family: $barlow;
							letter-spacing: 0.05em;
							padding: 1.15vw 2.1vw;
							line-height: 1.875vw;
							top: 0;
							right: 0;
							color: #000;
							@media screen and (max-width: 991px){
								font-size: 14px;
								line-height: 30px;
							}
							@media screen and (max-width: 767px){
								
							}
							@media screen and (max-width: 480px){
								
							}

							&:before{
								background-image: url(../images/svg/emplacement.svg);
								background-size: 100% 100%;
								background-repeat: no-repeat;
								background-position: center center;
								position: relative;
								display: block;
								content: "";
								width: 1.25vw;
								height: 1.875vw;
								float: left;
								margin: 0 1vw 0 0;
								@media screen and (max-width: 991px){
									width: 23px;
									height: 30px;
								}
								@media screen and (max-width: 767px){
									
								}
								@media screen and (max-width: 480px){
									
								}
							}
						}
					}
					.contenu{
						position: relative;
						h3{

							color: #fff;
							font-size: 3.45vw;
							font-weight: 700;
							line-height: 3.05vw;
							max-width: 23.5vw;
							margin: 3.75vw 0 4.6vw 0;
							@media screen and (max-width: 991px){
								font-size: 34px;
								max-width: 250px;
								line-height: 33px;
							}
							@media screen and (max-width: 767px){
								display: table;
							}
							a{
								color: #fff;
								transition: 0.4s;
								&:hover{
									color: $vert-p;
								}
							}
						}
						span{
							position: relative;
							display: block;
							color: $vert-p;
							font-weight: 500;
							font-size: 1.1vw;
							letter-spacing: 0.2010em;
							text-transform: uppercase;
							@media screen and (max-width: 991px){
								font-size: 14px;
							}
							@media screen and (max-width: 767px){
								
							}
							@media screen and (max-width: 580px){
								
							}
							.bar{

								background: $vert-p;
								position: absolute;
								display: block;
								content: "";
								width: 18.0523vw;
								height: 2px;
								top: 50%;
								right: 0;
								transform: translate(0, -50%);
								@media screen and (max-width: 991px){
									width: 11vw;
								}
								@media screen and (max-width: 767px){
									display: none;
								}
							}
						}
						.goTo{
							background-image: url(../images/svg/arrow-slide.svg);
							background-size: 100% 100%;
							background-position: center center;
							position: absolute;
							display: block;
							content: "";
							top: 50%;
							right: 0;
							width: 3.698vw;
							height: 1.875vw;
							transform: translate(0, -50%);
							transition: 0.4s;
							&:hover{
								right: 10px;
							}
							@media screen and (max-width: 991px){
								width: 37px;
								height: 19px;
							}
						}
					}
				}
			}
		}
	}
}