#intro-apropos{
	z-index: 5;
	@media screen and (max-width: 991px){
		margin: 8vw 0 0 0;
	}
	@media screen and (max-width: 580px){
		
	}
	#intro-apropos-support{
		height: 2.6vw;
	}
	.corps{
		.world-section{
			position: absolute;
			right: 0;
			bottom: -4.5vw;
			line-height: 100%;
			z-index: -1;
			@media screen and (max-width: 767px){
				display: none;
			}
			span{
				color: rgba(1, 1, 1, 0.2);
				font-size: 6.95vw;
				font-family: $barlow;
				font-weight: 900;
			}
		}
		#cc-slideshow-apropos{
			height: 100%;
			.carousel-indicators{
				top: 0;
				left: initial;
				right: -5.046vw;
				bottom: initial;
				width: initial;
				margin: 0;
				li{
					display: block;
					margin: 0;
					width: 12px;
					height: 12px;
					border: 1px solid #000;
					border-radius: initial;
					background-color: #000;
					& + li{
						margin: 12px 0 0 0;
					}
					&.active{
						background-color: transparent;
						cursor: initial;
					}
				}
			}
			.carousel-inner{
				height: 100%;
				overflow: initial;
				.panVid{

				}
				.item{
					height: 100%;
					.vid-play{
						background-color: #000;
						position: absolute;
						width: 82px;
						height: 72px;
						top: 50%;
						right: 30%;
						transform: translate(0, -50%);
						z-index: 4;
						cursor: pointer;
						@media screen and (max-width: 991px){

						}
						@media screen and (max-width: 767px){
							width: 72px;
							height: 62px;
							top: 50%;
							right: 50%;
							transform: translate(50%, -50%);
						}
						@media screen and (max-width: 580px){
							width: 62px;
							height: 52px;
						}
						@media screen and (max-width: 420px){
							width: 52px;
							height: 42px;
						}
						&:after{
							background-image: url(../images/svg/play-service.svg);
							background-repeat: no-repeat;
							background-size: 100% 100%;
							background-position: center center;
							position: absolute;
							display: block;
							content: "";
							top: 50%;
							left: 50%;
							width: 17px;
							height: 27px;
							filter: invert(100%);
							transform: translate(-50%, -50%);
							@media screen and (max-width: 767px){
								
							}
							@media screen and (max-width: 580px){
								width: 12px;
								height: 22px;
							}
							@media screen and (max-width: 420px){
								
							}
						}
					}
					.bg-img{
						background-color: #1e1e1e;
						background-position: center;
						background-size: cover;
						width: calc(100% - 14.333vw);
						height: 100%;
						float: right;
						@media screen and (max-width: 991px){
							width: 90%;
						}
						@media screen and (max-width: 767px){
							width: 100%;
							height: calc(100% - 100px);
							float: initial;
						}
						@media screen and (max-width: 580px){
							
						}
					}
					.bouton-mobile{
						display: none;
						@media screen and (max-width: 767px){
							display: table;
							width: 100%;
							margin: 30px 0 0 0;
						}
						a{
							border: 1px solid $vert-p;
							padding: 1vw 1.1vw;
							display: block;
							font-size: 0.85vw;
							font-family: $barlow;
							font-weight: 900;
							text-transform: uppercase;
							letter-spacing: 0.2010em;
							width: calc(50% - 10px);
							text-align: center;
							@media screen and (max-width: 991px){
								font-size: 1.3vw;
							}
							@media screen and (max-width: 767px){
								font-size: 13px;
								padding: 10px 12px;
							}
							@media screen and (max-width: 580px){
								
							}
							@media screen and (max-width: 420px){
								
							}
							&:nth-child(1){
								background-color: $vert-p;
								color: #fff;
								float: left;
							}
							&:nth-child(2){
								color: $vert-p;
								float: right;
							}
						}
					}
					.grand-titre{
						position: absolute;
						width: 100%;
						left: 0;
						bottom: 21.532vw;
						@media screen and (max-width: 991px){
							bottom: 28vw;
						}
						@media screen and (max-width: 767px){
							bottom: 30%;
							transform: translate(0, -50%);
							padding: 0 40px;
						}
						@media screen and (max-width: 580px){
							
						}
						@media screen and (max-width: 420px){
							
						}

						h2{
							color: #fff;
							font-size: 6.3vw;
							font-family: $barlow;
							font-weight: 800;
							line-height: 5.5vw;
							@media screen and (max-width: 580px){
								font-size: 42px;
								line-height: 40px;
							}
							@media screen and (max-width: 400px){
								font-size: 35px;
								line-height: 33px;
							}
						}
					}
					.boite-blanche{
						background: $vert-f;
						position: absolute;
						height: 21.532vw;
						z-index: 1;
						bottom: -2.6vw;
						@media screen and (max-width: 991px){
							width: 70% !important;
							padding: 3vw 0;
							height: auto;
						}
						@media screen and (max-width: 767px){
							display: none;
						}
						@media screen and (max-width: 580px){
							
						}
						@media screen and (max-width: 420px){
							
						}
						.boite-int{
							position: absolute;
							top: 50%;
							left: 0;
							transform: translate(0, -50%);
							padding: 0 4vw 0 0;
							@media screen and (max-width: 991px){
								position: relative;
								padding: 0 4vw 0 0;
								top: initial;
								left: initial;
								transform: initial;
							}
							@media screen and (max-width: 767px){
								position: relative;
								width: 80%;
							}
							@media screen and (max-width: 480px){
								padding: 0;
							}
							h5{
								position: relative;
								color: #fff;
								font-size: 1.1vw;
								font-family: $barlow;
								font-weight: 500;
								text-transform: uppercase;
								letter-spacing: 0.2010em;
								margin: 0 0 .4vw 0;
								@media screen and (max-width: 1175px){
									margin: 0 0 10px 0;
								}
								@media screen and (max-width: 991px){
									font-size: 1.9vw;
								}
								@media screen and (max-width: 767px){
									font-size: 2.3vw;
								}
								@media screen and (max-width: 580px){
									font-size: 13px;
								}
								.bar{

									background: #fff;
									position: absolute;
									display: block;
									content: "";
									height: 2px;
									top: 50%;
									left: 0;
									transform: translate(0, -50%);
								}
							}
							p{

								color: #000;
								font-size: 1.4vw;
								font-family: $barlow;
								font-weight: 700;
								letter-spacing: 0.05em;
								@media screen and (max-width: 991px){
									font-size: 1.9vw;
								}
								@media screen and (max-width: 767px){
									font-size: 2.3vw;
								}
								@media screen and (max-width: 580px){
									font-size: 15px;
									line-height: 24px;
								}
								@media screen and (max-width: 420px){
									
								}
							}
							.bouton{
								margin: 1.5vw 0 0;
								a{
									border: 1px solid #fff;
									padding: 1vw 1.1vw;
									display: inline-block;
									font-size: 0.85vw;
									font-family: $barlow;
									font-weight: 900;
									text-transform: uppercase;
									letter-spacing: 0.2010em;
									@media screen and (max-width: 991px){
										font-size: 1.3vw;
									}
									@media screen and (max-width: 767px){
										font-size: 1.7vw;
									}
									@media screen and (max-width: 580px){
										font-size: 13px;
									}
									@media screen and (max-width: 420px){
										
									}
									&:first-child{
										background-color: #fff;
										color: $vert-f;
										margin: 0 1vw 0 0;
									}
									&:last-child{
										color: #fff;
										@media screen and (max-width: 580px){
											margin: 10px 0 0 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

#desc-apropos{
	padding: 16vw 0px 11.8vw 0;
	z-index: 2;
	@media screen and (max-width: 767px){
		padding: 70px 0 11.8vw 0;
	}
	@media screen and (max-width: 420px){
		
	}
	.corps{

		p{
			color: $vert-p;
			font-size: 2.45vw;
			font-weight: 500;
			letter-spacing: 0.04em;
			line-height: 3.35vw;
			padding: 0 5vw 0 0;
			@media screen and (max-width: 991px){
				font-size: 23px;
				line-height: 30px;
			}
			@media screen and (max-width: 420px){
				font-size: 20px;
				text-align: center;
				line-height: 28px;
			}
		}
	}
}

#list-about{
	padding: 0 0 7.7vw 0;
	z-index: 3;
	@media screen and (max-width: 991px){
		padding: 0 0 120px 0;
	}
	@media screen and (max-width: 767px){
		
	}
	.corps{
		.bou{
			position: absolute;
			color: $vert-p;
			left: 0;
			bottom: -0.4vw;
			@media screen and (max-width: 991px){
				bottom: -90px;
				left: 50%;
				transform: translate(-50%, 0);
				text-align: center;
			}
			@media screen and (max-width: 767px){
				
			}
			@media screen and (max-width: 580px){
				width: 70%;
			}
			@media screen and (max-width: 420px){
				width: 80%;
			}
		}
		#row-un{
			height: 25.573vw;
			@media screen and (max-width: 991px){
				height: auto;
			}
			@media screen and (max-width: 767px){
				
			}
			@media screen and (max-width: 480px){
				
			}
			#left{
				background-color: #000;
				background-size: cover;
				background-repeat: no-repeat;
				position: relative;
				float: left;
				width: calc(50%);
				height: 100%;
				@media screen and (max-width: 991px){
					visibility: hidden;
					height: 1px;
				}
				@media screen and (max-width: 767px){
					background-image: initial !important;
				}
				#block-vert-left{
					background: $vert-f;
					position: absolute;
					bottom: 0;
					height: 50%;
					@media screen and (max-width: 991px){
						display: none;
					}
				}
			}
			#right{
				background-color: #fff;
				position: relative;
				float: left;
				width: calc(50%);
				height: 100%;

				#right-int{
					position: absolute;
					top: 50%;
					left: 50%;
					width: calc(100% - 11.4vw);
					transform: translate(-50%, -50%);
					@media screen and (max-width: 991px){
						position: relative;
						top: initial;
						left: initial;
						transform: initial;
						padding: 80px 60px;
						width: 100%;
					}
					@media screen and (max-width: 420px){
						padding: 40px;
					}
					h4{
						color: #000;
						font-size: 1.9vw;
						font-weight: 700;
						margin: 0 0 1vw 0;
						@media screen and (max-width: 991px){
							font-size: 24px;
							margin: 0 0 10px 0;
						}
						@media screen and (max-width: 767px){
							
						}
						@media screen and (max-width: 480px){
							
						}
					}
					p{
						color: #000;
						font-size: 1.1vw;
						@media screen and (max-width: 991px){
							font-size: 16px;
						}
						@media screen and (max-width: 767px){
							
						}
						@media screen and (max-width: 480px){
							
						}
					}
				}
			}
		}
		#row-deux{
			height: calc(25.573vw / 2);
			@media screen and (max-width: 991px){
				height: calc(30.573vw / 2);
			}
			@media screen and (max-width: 767px){
				height: calc(35.573vw / 2);
			}
			@media screen and (max-width: 480px){
				height: calc(40.573vw / 2);
			}
			#left-un{
				background-color: $vert-p;
				background-size: cover;
				background-repeat: no-repeat;
				float: left;
				height: 100%;
			}
			#left-deux{
				background-color: #000;
				position: relative;
				float: left;
				width: 25%;
				height: 100%;
				@media screen and (max-width: 991px){
					width: 50%;
				}
				.boite-int{
					position: absolute;
					width: 100%;
					top: 50%;
					text-align: center;
					transform: translate(0, -50%);
					span{
						color: #fff;
						font-size: 1.4vw;
						letter-spacing: 0.05em;
						@media screen and (max-width: 991px){
							font-size: 16px;
						}
						@media screen and (max-width: 767px){
							
						}
						@media screen and (max-width: 480px){
							
						}

						&:before{
							background-image: url(../images/svg/fiable.svg);
							background-repeat: no-repeat;
							background-size: auto 100%;
							background-position: center center;
							position: relative;
							display: block;
							content: "";
							width: 2.657vw;
							height: 2.657vw;
							margin: 0 auto 0.4vw;
							filter: invert(100%);
							@media screen and (max-width: 767px){
								display: none;
							}
						}
					}
				}
			}
			#right-un{
				background-color: #1a1a1a;
				position: relative;
				float: right;
				width: 25%;
				height: 100%;
			}

		}
		#row-trois{
			background-repeat: no-repeat;
			background-size: cover;
			position: relative;
			height: calc((25.573vw / 2) * 3);
			@media screen and (max-width: 991px){
				height: auto;
				background-image: none !important;
				background-color: #000;
			}
			@media screen and (max-width: 767px){
				
			}
			@media screen and (max-width: 480px){
				
			}

			#block-vert-right{
				background: $vert-f;
				position: absolute;
				right: 0;
				bottom: 0;
				height: calc(100% / 3);
				@media screen and (max-width: 991px){
					display: none;
				}
			}
			#line-un{
				height: calc(100% / 3);
				@media screen and (max-width: 991px){
					display: none;
				}
				#left{
					background: #000;
					width: 25%;
					height: 100%;
				}
			}
			#line-deux{
				height: calc(66.6666666666%);
				@media screen and (max-width: 991px){
					height: 100%;
				}
				#left{
					background: #fff;
					position: relative;
					width: 50vw;
					height: 100%;
					@media screen and (max-width: 991px){
						width: 80%;
					}

					#left-int{
						position: absolute;
						top: 50%;
						left: 50%;
						width: calc(100% - 11.4vw);
						transform: translate(-50%, -50%);
						@media screen and (max-width: 991px){
							position: relative;
							top: initial;
							left: initial;
							transform: initial;
							padding: 80px 60px;
							width: 100%;
						}
						@media screen and (max-width: 420px){
							padding: 40px;
						}
						h4{
							color: #000;
							font-size: 1.9vw;
							font-weight: 700;
							margin: 0 0 1vw 0;
							@media screen and (max-width: 991px){
								font-size: 24px;
								margin: 0 0 10px 0;
							}
							@media screen and (max-width: 767px){
								
							}
							@media screen and (max-width: 420px){
								
							}
						}
						p{
							color: #000;
							font-size: 1.1vw;
							@media screen and (max-width: 991px){
								font-size: 16px;
							}
							@media screen and (max-width: 767px){
								
							}
							@media screen and (max-width: 480px){
								
							}
						}
					}
				}
			}
		}
		#row-quatre{
			height: calc(25.573vw / 2);
			@media screen and (max-width: 991px){
				height: calc(30.573vw / 2);
			}
			@media screen and (max-width: 767px){
				height: calc(35.573vw / 2);
			}
			@media screen and (max-width: 480px){
				height: calc(40.573vw / 2);
			}
			#left-un{
				background-color: #000;
				background-size: cover;
				background-repeat: no-repeat;
				position: relative;
				float: left;
				width: 25%;
				height: 100%;
				@media screen and (max-width: 991px){
					width: 50%;
				}
				.boite-int{
					position: absolute;
					width: 100%;
					top: 50%;
					text-align: center;
					transform: translate(0, -50%);
					span{
						color: #fff;
						font-size: 1.4vw;
						letter-spacing: 0.05em;
						@media screen and (max-width: 991px){
							font-size: 16px;
						}
						@media screen and (max-width: 767px){
							
						}
						@media screen and (max-width: 480px){
							
						}
						&:before{
							background-image: url(../images/svg/architech.svg);
							background-repeat: no-repeat;
							background-size: auto 100%;
							background-position: center center;
							position: relative;
							display: block;
							content: "";
							width: 3.125vw;
							height: 3.125vw;
							margin: 0 auto 0.4vw;
							filter: invert(100%);
							@media screen and (max-width: 767px){
								display: none;
							}
						}
					}
				}
			}
			#right-un{
				background-color: #1a1a1a;
				position: relative;
				float: left;
				width: 25%;
				height: 100%;
				margin: 0 0 0 25%;
				@media screen and (max-width: 991px){
					margin: 0;
				}
			}
			#right-deux{
				background-color: $vert-p;;
				position: relative;
				float: right;
				width: 25%;
				height: 100%;
			}

		}
		#row-cinq{
			height: 25.573vw;
			@media screen and (max-width: 991px){
				height: auto;
			}
			@media screen and (max-width: 767px){
				
			}
			@media screen and (max-width: 480px){
				
			}
			#left{
				background-color: #000;
				background-size: cover;
				background-repeat: no-repeat;
				position: relative;
				float: left;
				width: calc(50%);
				height: 100%;
				@media screen and (max-width: 991px){
					visibility: hidden;
					height: 1px;
				}
				#block-vert-left{
					background: $vert-f;
					position: absolute;
					bottom: 0;
					height: 50%;
				}
			}
			#right{
				background-color: #fff;
				position: relative;
				float: left;
				width: calc(50%);
				height: 100%;

				#right-int{
					position: absolute;
					top: 50%;
					left: 50%;
					width: calc(100% - 14.5vw);
					transform: translate(-50%, -50%);
					@media screen and (max-width: 991px){
						position: relative;
						top: initial;
						left: initial;
						transform: initial;
						padding: 80px 60px;
						width: 100%;
					}
					@media screen and (max-width: 420px){
						padding: 40px;
					}
					h4{
						color: #000;
						font-size: 1.9vw;
						font-weight: 700;
						margin: 0 0 1vw 0;
						@media screen and (max-width: 991px){
							font-size: 24px;
							margin: 0 0 10px 0;
						}
						@media screen and (max-width: 767px){
							
						}
						@media screen and (max-width: 480px){
							
						}
					}
					p{
						color: #000;
						font-size: 1.1vw;
						@media screen and (max-width: 991px){
							font-size: 16px;
						}
						@media screen and (max-width: 767px){
							
						}
						@media screen and (max-width: 480px){
							
						}
					}
				}
			}
		}
		#row-six{
			height: calc(25.573vw / 2);
			@media screen and (max-width: 991px){
				height: calc(30.573vw / 2);
			}
			@media screen and (max-width: 767px){
				height: calc(35.573vw / 2);
			}
			@media screen and (max-width: 480px){
				height: calc(40.573vw / 2);
			}
			#left{
				background-color: #000;
				position: relative;
				float: left;
				width: 25%;
				height: 100%;
				margin: 0 0 0 50%;
				@media screen and (max-width: 991px){
					width: 50%;
					margin: 0 0 0 25%;
				}
				@media screen and (max-width: 767px){
					width: 50%;
					margin: 0;
				}
				.boite-int{
					position: absolute;
					width: 100%;
					top: 50%;
					text-align: center;
					transform: translate(0, -50%);
					span{
						color: #fff;
						font-size: 1.4vw;
						letter-spacing: 0.05em;
						@media screen and (max-width: 991px){
							font-size: 16px;
						}
						@media screen and (max-width: 767px){
							
						}
						@media screen and (max-width: 480px){
							
						}
						&:before{
							background-image: url(../images/svg/your-image.svg);
							background-repeat: no-repeat;
							background-size: auto 100%;
							background-position: center center;
							position: relative;
							display: block;
							content: "";
							width: 4.115vw;
							height: 3.282vw;
							margin: 0 auto 0.4vw;
							filter: invert(100%);
							@media screen and (max-width: 767px){
								display: none;
							}
						}
					}
				}
			}
		}
	}
}