/* =======================================================================
Responsive
========================================================================== */
$breakpoints: (
	'xsmall': 		(max-width: 479px),
	'small': 		(max-width: 767px),
	'medium': 		(max-width: 992px),
	'large': 		(max-width: 1199px),
	'xxl': 			(max-width: 1440px)
);


/* =======================================================================
Font-face
========================================================================== */

// @font-face {
// 	font-family: 'font-name-here';
// 	src: url('../fonts/font-name-here/font-name-here.ttf') format("truetype"),
// 		 url('../fonts/font-name-here/font-name-here.woff') format("woff"),
// 		 url('../fonts/font-name-here/font-name-here.woff2') format("woff2"),
// 		 url('../fonts/font-name-here/font-name-here.eot#iefix');
// }


/* =======================================================================
Variables
========================================================================== */

// recall fonts
$barlow: 'Barlow', sans-serif;

// Colors
$vert-f: #79ac05;
$vert-p: #a4c83f;


// KEYFRAMES
@keyframes opac{
	0%{
		opacity: 0;
	}
	100%{
		opacity: 1;
	}
}