#intro-services{
	height: calc(42.918vw - 11.511vw);
	z-index: 4;
	@media screen and (max-width: 991px){
		height: calc(45.918vw - 115px);
	}
	@media screen and (max-width: 767px){
		height: calc(48.918vw - 70px);
	}
	@media screen and (max-width: 580px){
		height: auto;
		padding: 0 0 60px 0;
	}
	.corps{

		.contenu{
			position: absolute;
			top: calc(50% - (11.511vw / 2);
			left: 0;
			width: 100%;
			transform: translate(0, -50%);
			@media screen and (max-width: 767px){
				transform: translate(0, -60%);
			}
			@media screen and (max-width: 580px){
				position: initial;
				top: initial;
				left: initial;
				transform: initial;
			}

			h1{
				color: #fff;
				font-size: 5.55vw;
				font-weight: 800;
				line-height: 5.5vw;
				float: left;
				@media screen and (max-width: 991px){
					font-size: 6vw;
				}
				@media screen and (max-width: 767px){
					font-size: 40px;
					margin: 0 0 15px 0;
				}
				@media screen and (max-width: 580px){
					padding: 60px 0;
				}
				@media screen and (max-width: 420px){
					font-size: 30px;
					padding: 60px 0 30px 0;
				}
			}

		}
		.boite-blanche-serv{
			background-color: #fff;
			position: absolute;
			left: 0;
			bottom: calc(-17.188vw / 2);
			height: 17.188vw;
			z-index: 3;
			@media screen and (max-width: 991px){
				height: auto;
				padding: 6vw 0;
			}
			@media screen and (max-width: 767px){
				height: auto;
				padding: 5vw 0;
			}
			@media screen and (max-width: 580px){
				height: auto;
				padding: 40px 0;
				bottom: initial;
				position: relative;
				clear: both;
			}
			.box-int{
				position: absolute;
				width: 52.084vw;
				top: 50%;
				transform: translate(0, -50%);
				@media screen and (max-width: 991px){
					position: initial;
					top: initial;
					transform: initial;
				}
				@media screen and (max-width: 580px){
					width: 100%;
					margin: 0 !important;
					padding: 0 40px;
				}
				#contenu{
					.box-part{
						position: absolute;
						top: 50%;
						transform: translate(0, -50%);
						color: #000;
						@media screen and (max-width: 991px){
							position: initial;
							top: initial;
							transform: initial;
						}
						&:nth-child(1){
							left: 0;
							width: 20.0523vw;
							@media screen and (max-width: 580px){
								width: 100%;
							}
							h4{
								font-size: 3.15vw;
								font-weight: 800;
								@media screen and (max-width: 991px){
									font-size: 3.5vw;
								}
								@media screen and (max-width: 767px){
									font-size: 4vw;
								}
								@media screen and (max-width: 580px){
									font-size: 24px;
								}
							}
						}

						&:nth-child(2){
							right: 0;
							width: calc(100% - 20.0523vw);
							@media screen and (max-width: 991px){
								width: calc(100% - 10vw);
								right: initial;
								margin: 0 0 0 4vw;
							}
							@media screen and (max-width: 767px){
								width: calc(100% - 6vw);
								margin: 0 0 0 3vw;
							}
							@media screen and (max-width: 580px){
								width: calc(100%);
								margin: 0 0 0 3vw;
							}
							p{
								font-size: 1.1vw;
								letter-spacing: 0.05em;
								@media screen and (max-width: 991px){
									font-size: 1.4vw;
								}
								@media screen and (max-width: 767px){
									font-size: 1.9vw;
								}
								@media screen and (max-width: 580px){
									font-size: 14px;
								}
							}
						}
					}
				}
			}
		}
	}
}

#desc-service{
	background: #0f0f0f;
	padding: 16.5vw 0px 13.8vw 0;
	z-index: 2;
	@media screen and (max-width: 580px){
		padding: 70px 0;
	}
	@media screen and (max-width: 420px){
		
	}
	.corps{

		p{
			color: $vert-p;
			font-size: 2.45vw;
			font-weight: 500;
			letter-spacing: 0.04em;
			line-height: 3.35vw;
			@media screen and (max-width: 767px){
				font-size: 3vw;
				line-height: 4vw;
			}
			@media screen and (max-width: 420px){
				font-size: 20px;
				text-align: center;
				line-height: 28px;
			}
		}
	}
}

#service-present{
	height: 47.761vw;
	z-index: 3;
	@media screen and (max-width: 767px){
		height: 57.761vw;
	}
	@media screen and (max-width: 580px){
		height: auto;
	}
	#centre-design{
		background: $vert-f;
		position: absolute;
		width: 0.6778vw;
		height: 7.709vw;
		left: 0;
		bottom: 0;
	}
	#carre{
		background: $vert-f;
		position: absolute;
		height: 12.865vw;
		top: 9.45vw;
		left: 0;
	}
	.corps{
		#rect{
			background: $vert-p;
			position: absolute;
			height: 12.813vw;
			top: calc(9.45vw + 12.865vw);
			left: 0;
			@media screen and (max-width: 580px){
				display: none;
			}
		}
		#left{
			float: left;
			width: calc(50% - 1.572vw);
			height: 100%;
			padding: 7vw 0 0 0;
			@media screen and (max-width: 991px){
				width: calc(70% - 1.572vw);
			}
			@media screen and (max-width: 767px){
				width: 100%;
			}
			@media screen and (max-width: 580px){
				float: initial;
			}
			p{
				color: #fff;
				font-size: 1.1vw;
				line-height: 1.4vw;
				letter-spacing: 0.05em;
				max-width: 24.167vw;
				margin: 0 auto;
				@media screen and (max-width: 1475px){
					font-size: 1.2vw;
					line-height: 1.6vw;
				}
				@media screen and (max-width: 991px){
					max-width: calc(24.167vw + 20%);
					font-size: 1.5vw;
					line-height: 2vw;
				}
				@media screen and (max-width: 767px){
					max-width: calc(24.167vw + 50%);
					font-size: 2vw;
					line-height: 2.8vw;
				}
				@media screen and (max-width: 580px){
					max-width: calc(24.167vw + 50%);
					font-size: 14px;
					line-height: 28px;
				}
			}
		}
		#right{
			position: relative;
			float: left;
			width: calc(50% + 1.572vw);
			height: 100%;
			@media screen and (max-width: 991px){
				width: calc(30% + 1.572vw);
			}
			@media screen and (max-width: 767px){
				position: absolute;
				right: 0;
				width: 65%;
			}
			#img{
				background-color: #000;
				background-size: cover;
				position: absolute;
				width: 100%;
				height: 28.646vw;
				top: -6.2vw;
				@media screen and (max-width: 991px){
					right: 0;
				}
				@media screen and (max-width: 767px){
					background-image: none !important;
					background-color: #0f0f0f;
				}
				@media screen and (max-width: 580px){
					display: none;
				}
			}
			.centre-button{
				margin: 29.75vw 0 0 0;
				@media screen and (max-width: 991px){
					margin: 25.75vw 0 0 0;
				}
				@media screen and (max-width: 580px){
					display: none;
				}
				a{
					@media screen and (max-width: 991px){
						display: block;
						width: 100%;
						text-align: center;
						& + a{
							margin: 2vw 0 0 0;
						}
					}
					color: $vert-p;
					&:nth-child(1){
						background-color: $vert-p;
						margin: 0 2vw 0 0;
						color: #fff;
					}
				}
			}
		}
	}
}

#reno-present{
	height: 47.761vw;
	z-index: 2;
	@media screen and (max-width: 991px){
		height: 60vw;
	}
	@media screen and (max-width: 767px){
		height: 75vw;
	}
	@media screen and (max-width: 580px){
		height: auto;
	}
	.corps{
		.boite-blanche-serv{
			background-color: #fff;
			position: absolute;
			top: calc(-17.188vw / 2);
			right: 0;
			height: 17.188vw;
			z-index: 3;
			@media screen and (max-width: 991px){
				height: auto;
				padding: 6vw 0;
			}
			@media screen and (max-width: 767px){
				height: auto;
				padding: 5vw 0 5vw 3vw;
			}
			@media screen and (max-width: 580px){
				height: auto;
				padding: 40px 0;
				bottom: initial;
				position: relative;
				clear: both;
				top: initial;
				margin: 60px 0;
			}
			.box-int{
				position: absolute;
				width: calc(100% - 11.98vw);
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				@media screen and (max-width: 991px){
					position: initial;
					top: initial;
					transform: initial;
					margin: 0 0 0 20%;
				}
				@media screen and (max-width: 580px){
					width: 100%;
					margin: 0 !important;
					padding: 0 40px;
				}
				#contenu{
					.box-part{
						position: absolute;
						top: 50%;
						transform: translate(0, -50%);
						color: #000;
						@media screen and (max-width: 991px){
							position: initial;
							top: initial;
							transform: initial;
						}
						&:nth-child(1){
							left: 0;
							width: 18.0523vw;
							h4{
								font-size: 3.15vw;
								font-weight: 800;
								@media screen and (max-width: 991px){
									font-size: 3.5vw;
								}
								@media screen and (max-width: 767px){
									font-size: 4vw;
								}
								@media screen and (max-width: 580px){
									font-size: 24px;
								}
							}
						}

						&:nth-child(2){
							left: 18.0523vw;
							width: calc(100% - 18.0523vw);
							@media screen and (max-width: 991px){
								width: calc(100% - 10vw);
								right: initial;
								margin: 0 0 0 4vw;
							}
							@media screen and (max-width: 767px){
								width: calc(100% - 6vw);
								margin: 0 0 0 3vw;
							}
							@media screen and (max-width: 580px){
								width: calc(100%);
								margin: 0 0 0 3vw;
							}
							p{
								font-size: 1.1vw;
								letter-spacing: 0.05em;
								@media screen and (max-width: 991px){
									font-size: 1.4vw;
								}
								@media screen and (max-width: 767px){
									font-size: 1.9vw;
								}
								@media screen and (max-width: 580px){
									font-size: 14px;
								}
							}
						}
					}
				}
			}
		}
		#left{
			float: left;
			width: 45.834vw;
			height: 100%;
			@media screen and (max-width: 991px){
				width: calc(100%);
			}
			@media screen and (max-width: 767px){
				
			}
			@media screen and (max-width: 580px){
				
			}
			#img{
				background-color: #000;
				background-size: cover;
				position: relative;
				display: block;
				width: 100%;
				height: 28.646vw;
				@media screen and (max-width: 580px){
					display: none;
				}
			}
			p{
				color: #fff;
				font-size: 1.1vw;
				letter-spacing: 0.05em;
				max-width: 33.5vw;
				margin: 7.3vw 0 0 0;
				@media screen and (max-width: 1475px){
					font-size: 1.2vw;
					line-height: 1.6vw;
				}
				@media screen and (max-width: 991px){
					margin: 16vw 0 0 0;
					max-width: 55vw;
					font-size: 1.5vw;
					line-height: 2vw;
				}
				@media screen and (max-width: 767px){
					max-width: calc(60vw);
					font-size: 2vw;
					line-height: 2.8vw;
					margin: 18vw 0 0 0;
				}
				@media screen and (max-width: 580px){
					max-width: calc(100%);
					font-size: 14px;
					line-height: 28px;
					margin: 0;
				}
			}

		}
		#right{
			position: relative;
			float: left;
			width: calc(100% - 45.834vw);
			height: 100%;
			@media screen and (max-width: 991px){
				width: calc(100% - 35vw);
				position: absolute;
				right: 0;
			}
			@media screen and (max-width: 767px){
				width: calc(100% - 20vw);
			}
			@media screen and (max-width: 580px){
				width: 100%;
				position: relative;
				right: initial;
				top: initial;
				bottom: initial;
				left: initial;
			}
			ul{
				max-width: 25.3vw;
				margin: calc((17.188vw / 2) + 6.3vw) auto 0 auto;
				@media screen and (max-width: 767px){
					max-width: 100%;
					padding: 0 4vw;
					margin: 0;
					position: absolute;
					top: calc((18.188vw / 2) + 6.3vw);
				}
				@media screen and (max-width: 580px){
					top: initial;
					position: relative;
					padding: 60px 0;
				}
				li{
					color: $vert-p;
					font-size: 1.4vw;
					letter-spacing: 0.05em;
					height: 3.49vw;
					line-height: 3.49vw;
					border-bottom: 1px solid $vert-p;
					@media screen and (max-width: 991px){
						height: auto;
						font-size: 1.9vw;
						line-height: 2.5vw;
						& + li{
							margin: 1.2vw 0 0 0;
						}
					}
					@media screen and (max-width: 767px){
						font-size: 2.4vw;
						& + li{
							margin: 1.8vw 0 0 0;
							@media screen and (max-width: 580px){
								margin: 15px 0 0 0 !important;
							}
						}
					}
					@media screen and (max-width: 580px){
						font-size: 16px !important;
						line-height: 20px;
					}
					&:last-child{
						border: none !important;
					}
				}
			}
		}
		.centre-button-mobile{
			display: none;
			margin: 0 0 60px 0;
			@media screen and (max-width: 580px){
				display: block;
				width: 100%;
			}
			.bou{
				display: block;
				width: 100%;
				text-align: center;
				& + .bou{
					margin: 15px 0 0 0;
				}
				color: $vert-p;
				&:nth-child(1){
					background-color: $vert-p;
					color: #fff;
				}
			}
		}
	}
}