footer{
	position: relative;
	background: #0f0f0f;
	height: 16.042vw;
	z-index: 4;
	#returnToTop{
		background-image: url(../images/svg/arrow-slick.svg);
		background-size: 60px 25px;
		background-repeat: no-repeat;
		background-position: center center;
		background-color: $vert-p;
		position: absolute;
		width: 110px;
		height: 84px;
		top: -122px;
		right: 0;
		cursor: pointer;
		transform: rotate(-90deg);
		z-index: 9;
		@media screen and (max-width: 580px){
			position: relative;
			top: initial;
			right: initial;
			left: 50%;
			transform: translate(-50%, 0) rotate(-90deg);
			background-size: 50px 15px;
		}
	}
	@media screen and (max-width: 991px){
		height: auto;
		padding: 4vw 0;
	}
	@media screen and (max-width: 580px){
		background: $vert-p;
		height: auto;
		padding: 13px 0;
	}
	
	.corps{

		.part-footer{
			position: absolute;
			top: 50%;
			transform: translate(0, -50%);
			@media screen and (max-width: 991px){
				position: initial;
				top: initial;
				transform: initial;
				margin: 0 auto 0 auto;

				& + .part-footer{
					margin: 4vw auto 0 auto;
				}
			}
			@media screen and (max-width: 580px){
				display: none;

				& + .part-footer{
					margin: 7vw auto 0 auto;
				}
			}
			&#logo-copyright{
				width: 10.157vw;
				left: 0;
				@media screen and (max-width: 1445px){
					width: 11.157vw;
				}
				@media screen and (max-width: 991px){
					width: 20vw;
				}
				@media screen and (max-width: 767px){
					width: 23vw;
				}
				@media screen and (max-width: 480px){
					width: 29vw;
				}

				a{

					img{

					}
				}
				span{

					display: block;
					color: #555555;
					font-size: 0.8vw;
					text-align: center;
					letter-spacing: 0.05em;
					margin: 1vw 0 0 0;
					@media screen and (max-width: 1445px){
						font-size: 1.1vw;
					}
					@media screen and (max-width: 991px){
						font-size: 1.7vw;
					}
					@media screen and (max-width: 767px){
						font-size: 2.2vw;
					}
					@media screen and (max-width: 480px){
						font-size: 2.8vw;
					}
				}
			}
			&#gala-habitation{
				width: 6.3vw;
				left: 15.9vw;
				@media screen and (max-width: 1445px){
					left: 16.9vw;
					width: 8.3vw;
				}
				@media screen and (max-width: 991px){
					width: 11vw;
				}
				@media screen and (max-width: 767px){
					width: 15vw;
				}
				@media screen and (max-width: 580px){
					display: none;
				}
				@media screen and (max-width: 480px){
					
				}
				img{
					display: block;
					width: 61px;
					height: auto;
					filter: invert(100%);
					margin: 0 auto 1vw;
					@media screen and (max-width: 991px){
						width: 9vw;
					}
					@media screen and (max-width: 480px){
						width: 13vw;
					}
				}
				span{
					display: block;
					color: #555555;
					font-size: 0.8vw;
					text-align: center;
					letter-spacing: 0.05em;
					@media screen and (max-width: 1445px){
						font-size: 1.1vw;
					}
					@media screen and (max-width: 991px){
						font-size: 1.5vw;
					}
					@media screen and (max-width: 767px){
						font-size: 2vw;
					}
					@media screen and (max-width: 480px){
						font-size: 2.8vw;
					}
				}
			}
			&#navigation{
				right: 50%;
				transform: translate(50%, -50%);
				@media screen and (max-width: 1445px){
					top: 30%;
					right: 0;
					transform: initial;
				}
				@media screen and (max-width: 991px){
					margin: 6vw auto 4vw auto;
				}
				@media screen and (max-width: 767px){
					margin: 6.5vw auto 0 auto;
				}
				@media screen and (max-width: 480px){
					margin: 7vw auto 0 auto;
				}
				ul{
					display: inline-flex;
					@media screen and (max-width: 991px){
						display: table;
						width: 100%;
					}
					li{
						margin: 0 2.9vw 0 0;
						@media screen and (max-width: 991px){
							display: table-cell;
							text-align: center;
							vertical-align: middle;
							width: 25%;
						}
						@media screen and (max-width: 767px){
							display: table;
							margin: 0 auto !important;
							width: 100%;
							text-align: center;
							& + li{
								margin: 3vw 0 0 0;
							}
						}

						&:last-child{
							margin: 0;
						}

						a{
							color: #505050;
							font-size: 0.85vw;
							letter-spacing: 0.05em;
							transition: 0.4s;
							&:hover{
								color: #fff !important;
							}
							@media screen and (max-width: 1445px){
								font-size: 1.3vw;
							}
							@media screen and (max-width: 991px){
								font-size: 1.8vw;
							}
							@media screen and (max-width: 767px){
								font-size: 2.4vw;
							}
							@media screen and (max-width: 480px){
								font-size: 3vw;
							}
						} 
					}
				}
			}
			&#copyright-cc{
				top: initial;
				bottom: 30px;
				right: 50%;
				transform: translate(50%, 0);
				@media screen and (max-width: 991px){
					text-align: center;
					transform: initial;
					right: initial;
				}
				a{
					color: #fff;
					font-size: 11px;
					font-family: $barlow;
					img{
						filter: invert(100%);
						margin: 0 0 0 10px;
					}
				}
			}
			&#contact{
				right: 0;
				@media screen and (max-width: 1445px){
					top: initial;
					bottom: 30%;
					transform: initial;
				}
				a{
					color: #fff;
					font-size: 1.1vw;
					line-height: 2.5vw;
					letter-spacing: 0.05em;
					@media screen and (max-width: 991px){
						display: table;
						margin: 0 auto;
						font-size: 1.9vw;
						line-height: 4vw;
					}
					@media screen and (max-width: 767px){
						font-size: 2.4vw;
						line-height: 5vw;
					}
					@media screen and (max-width: 480px){
						font-size: 3vw;
						line-height: 6vw;
					}
					&:before{
						background-image: url(../images/svg/tel.svg);
						background-size: 100% 100%;
						background-repeat: no-repeat;
						background-position: center center;
						position: relative;
						display: block;
						content: "";
						width: 1.511vw;
						height: 2.5vw;
						float: left;
						margin: 0 1.55vw 0 0;
						@media screen and (max-width: 991px){
							width: 2vw;
							height: 4vw;
						}
						@media screen and (max-width: 767px){
							width: 3vw;
							height: 5vw;
						}
						@media screen and (max-width: 480px){
							width: 4vw;
							height: 6vw;
						}
					}
				}
			}
		}
	}
}