#cc-slideshow-intro{
	z-index: 2;
	@media screen and (max-width: 991px){
		margin: 8vw 0 0 0;
	}
	@media screen and (max-width: 767px){
		margin: 60px 0 0 0;
	}
	@media screen and (max-width: 580px){
		
	}
	#intro-home-support{
		height: 2.6vw;
	}
	.corps{
		.world-section{
			position: absolute;
			right: 0;
			bottom: -4.5vw;
			line-height: 100%;
			z-index: -1;
			@media screen and (max-width: 767px){
				display: none;
			}
			span{
				color: rgba(1, 1, 1, 0.2);
				font-size: 6.95vw;
				font-family: $barlow;
				font-weight: 900;
			}
		}
		#cc-slideshow{
			height: 100%;
			.carousel-indicators{
				top: 0;
				left: initial;
				right: -5.046vw;
				bottom: initial;
				width: initial;
				margin: 0;
				li{
					display: block;
					margin: 0;
					width: 12px;
					height: 12px;
					border: 1px solid #000;
					border-radius: initial;
					background-color: #000;
					& + li{
						margin: 12px 0 0 0;
					}
					&.active{
						background-color: transparent;
						cursor: initial;
					}
				}
			}
			.carousel-inner{
				height: 100%;
				overflow: initial;
				.item{
					height: 100%;
					.bg-img{
						background-color: #1e1e1e;
						background-position: center;
						background-size: cover;
						width: calc(100% - 14.333vw);
						height: 100%;
						float: right;
						opacity: 0.6;
						@media screen and (max-width: 991px){
							width: 90%;
						}
						@media screen and (max-width: 767px){
							float: initial;
							width: 100%;
							height: calc(100% - 100px);
						}
						@media screen and (max-width: 580px){
							width: 100%;
						}
					}
					.bouton-mobile{
						display: none;
						@media screen and (max-width: 767px){
							display: table;
							width: 100%;
							margin: 30px 0 0 0;
						}
						a{
							border: 1px solid $vert-p;
							padding: 1vw 1.1vw;
							display: block;
							font-size: 0.85vw;
							font-family: $barlow;
							font-weight: 900;
							text-transform: uppercase;
							letter-spacing: 0.2010em;
							width: calc(50% - 10px);
							text-align: center;
							@media screen and (max-width: 991px){
								font-size: 1.3vw;
							}
							@media screen and (max-width: 767px){
								font-size: 13px;
								padding: 10px 12px;
							}
							@media screen and (max-width: 580px){
								
							}
							@media screen and (max-width: 420px){
								
							}
							&:nth-child(1){
								background-color: $vert-p;
								color: #fff;
								float: left;
							}
							&:nth-child(2){
								color: $vert-p;
								float: right;
							}
						}
					}
					.grand-titre{
						position: absolute;
						width: 100%;
						left: 0;
						bottom: 21.532vw;
						@media screen and (max-width: 991px){
							bottom: 25vw;
						}
						@media screen and (max-width: 767px){
							bottom: 50%;
							transform: translate(0, 50%);
							padding: 40px;
						}
						@media screen and (max-width: 580px){
							
						}
						@media screen and (max-width: 420px){
							
						}

						h2{
							color: #fff;
							font-size: 6.3vw;
							font-family: $barlow;
							font-weight: 800;
							line-height: 5.5vw;
							@media screen and (max-width: 580px){
								font-size: 42px;
								line-height: 40px;
							}
							@media screen and (max-width: 400px){
								font-size: 35px;
								line-height: 33px;
							}
						}
					}
					.boite-blanche{
						background: #fff;
						position: absolute;
						height: 19.532vw;
						z-index: 1;
						bottom: -2.6vw;
						@media screen and (max-width: 991px){
							width: 70% !important;
							padding: 3vw 0;
							height: auto;
						}
						@media screen and (max-width: 767px){
							display: none !important;
						}
						@media screen and (max-width: 580px){
							
						}
						@media screen and (max-width: 420px){
							
						}
						.boite-int{
							position: absolute;
							top: 50%;
							left: 0;
							transform: translate(0, -50%);
							padding: 0 4vw 0 0;
							@media screen and (max-width: 991px){
								position: relative;
								padding: 0 4vw 0 0;
								top: initial;
								left: initial;
								transform: initial;
							}
							@media screen and (max-width: 767px){
								position: relative;
								width: 80%;
							}
							@media screen and (max-width: 480px){
								padding: 0;
							}
							h5{
								position: relative;
								color: $vert-p;
								font-size: 1.05vw;
								font-family: $barlow;
								font-weight: 500;
								text-transform: uppercase;
								letter-spacing: 0.2010em;
								margin: 0 0 .4vw 0;
								@media screen and (max-width: 1175px){
									margin: 0 0 10px 0;
								}
								@media screen and (max-width: 991px){
									font-size: 1.9vw;
								}
								@media screen and (max-width: 767px){
									font-size: 2.3vw;
								}
								@media screen and (max-width: 580px){
									font-size: 13px;
								}
								.bar{

									background: $vert-p;
									position: absolute;
									display: block;
									content: "";
									height: 2px;
									top: 50%;
									left: 0;
									transform: translate(0, -50%);
								}
							}
							p{

								color: #000;
								font-size: 1.4vw;
								font-family: $barlow;
								font-weight: 700;
								letter-spacing: 0.05em;
								@media screen and (max-width: 991px){
									font-size: 1.9vw;
								}
								@media screen and (max-width: 767px){
									font-size: 2.3vw;
								}
								@media screen and (max-width: 580px){
									font-size: 15px;
									line-height: 24px;
								}
								@media screen and (max-width: 420px){
									
								}
							}
							.bouton{
								margin: 1.5vw 0 0;
								@media screen and (max-width: 1175px){
									margin: 15px 0 0 0;
								}
								a{
									border: 1px solid $vert-p;
									padding: 1vw 1.1vw;
									display: inline-block;
									font-size: 0.85vw;
									font-family: $barlow;
									font-weight: 900;
									text-transform: uppercase;
									letter-spacing: 0.2010em;
									@media screen and (max-width: 991px){
										font-size: 1.3vw;
									}
									@media screen and (max-width: 767px){
										font-size: 1.7vw;
									}
									@media screen and (max-width: 580px){
										font-size: 13px;
									}
									@media screen and (max-width: 420px){
										
									}
									&:first-child{
										background-color: $vert-p;
										color: #fff;
										margin: 0 1vw 0 0;
									}
									&:last-child{
										color: $vert-p;
										@media screen and (max-width: 580px){
											margin: 10px 0 0 0;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

#services{
	position: relative;
	padding: 12.4vw 0 0 0;
	z-index: 2;
	@media screen and (max-width: 767px){
		padding: 17vw 0 0 0;
	}
	@media screen and (max-width: 580px){
		padding: 70px 0 0 0;
	}
	@media screen and (max-width: 420px){
		
	}
	.sec-redirect{
		position: absolute;
		left: 0;
		bottom: 0;
		max-width: 26.823vw;
		z-index: 2;
		@media screen and (max-width: 767px){
			position: initial;
			display: block;
			max-width: initial
		}
		span{
			color: rgba(1, 1, 1, 0.2);
			font-size: 6.95vw;
			font-family: $barlow;
			font-weight: 900;
			line-height: 4.8vw;
			@media screen and (max-width: 767px){
				display: none;
			}
		}
		a{
			border: 1px solid $vert-p;
			padding: 1vw 1.1vw;
			display: inline-block;
			font-size: 0.85vw;
			font-family: $barlow;
			font-weight: 900;
			text-transform: uppercase;
			letter-spacing: 0.2010em;
			color: $vert-p;
			margin: 2vw 0 0 0;
			float: right;
			@media screen and (max-width: 1175px){
				font-size: 1.2vw;
			}
			@media screen and (max-width: 991px){
				font-size: 1.4vw;
			}
			@media screen and (max-width: 767px){
				font-size: 13px;
				padding: 12px 15px;
				text-align: center;
				margin: 15px auto 0 auto;
				float: initial;
				display: table;
			}
			@media screen and (max-width: 580px){
				
			}
			@media screen and (max-width: 420px){
				
			}
		}
	}
	.corps{

		.titre{
			position: relative;
			margin: 0 0 3.8vw 0;
			@media screen and (max-width: 991px){
				margin: 0 0 5vw 0;
			}
			@media screen and (max-width:580px){
				margin: 0 0 6vw 0;
			}
			h4{
				color: #fff;
				font-size: 3.15vw;
				font-family: $barlow;
				font-weight: 900;
				float: left;
				width: 18.5vw;
				@media screen and (max-width: 1200px){
					display: block;
					float: initial;
					font-size: 4vw;
					width: 100%;
					margin: 0 0 2vw 0;
				}
				@media screen and (max-width: 991px){
					font-size: 4.7vw;
				}
				@media screen and (max-width: 767px){
					font-size: 40px;
				}
				@media screen and (max-width: 420px){
					font-size: 30px;
				}
			}
			p{
				position: absolute;
				float: left;
				color: #fff;
				font-size: 1.1vw;
				font-family: $barlow;
				font-weight: 400;
				float: left;
				width: 37vw;
				top: 50%;
				left: 18.5vw;
				padding: 0 0 0 40px;
				transform: translate(0, -50%);
				letter-spacing: 0.05em;
				@media screen and (max-width: 1200px){
					position: initial;
					font-size: 1.5vw;
					top: initial;
					transform: initial;
					width: 50vw;
				}
				@media screen and (max-width: 991px){
					font-size: 14px;
					width: 55vw;
				}
				@media screen and (max-width: 580px){
					width: 100%;
				}
			}
		}

		.grid-services{
			.grid-row{
				.int{
					position: absolute;
					width: 100%;
					top: 50%;
					left: 0;
					transform: translate(0, -50%);
					.img{
						background-size: auto 100%;
						background-repeat: no-repeat;
						background-position: center;
						height: 2.865vw;
						@media screen and (max-width: 767px){
							display: none;
						}
					}
					span{
						color: #000;
						font-size: 1.4vw;
						font-family: $barlow;
						text-align: center;
						display: block;
						margin: 1.2vw 0 0 0;
						@media screen and (max-width: 767px){
							margin: 0;
							font-size: 2.6vw;
						}
						@media screen and (max-width: 580px){
							font-size: 19px;
							padding: 0 10px;
						}
					}
				}
				&#row-un{
					height: 12.761vw;
					@media screen and (max-width: 991px){
						height: 15.761vw;
					}
					@media screen and (max-width: 480px){
						height: 18.761vw;
					}
					.with-row{
						position: relative;
						height: 100%;
						float: left;
						.int{

							.img{
								display: block;
								width: auto;
								height: 2.865vw;
								filter: invert(100%);
								@media screen and (max-width: 767px){
									display: none;
								}
							}
							span{
								color: #fff;
							}
						}
						&#left{
							background-color: $vert-p;
						}
						&#centre{
							background-color: #000;
							width: 50%;
							@media screen and (max-width: 991px){
								width: 75%;
							}
							@media screen and (max-width: 767px){
								width: 100%;
							}
						}
						&#right{
							background-color: #1e1e1e;
							background-image: url(../images/grid-photo-1.jpg);
							background-size: cover;
							background-repeat: no-repeat;
							background-position: 100% 0;
							width: 25%;
							@media screen and (max-width: 767px){
								display: none;
							}
						}
					}
				}
				&#row-deux{
					position: relative;
					height: 25.417vw;
					@media screen and (max-width: 991px){
						height: 31.417vw;
					}
					@media screen and (max-width: 480px){
						height: 37.417vw;
					}
					#special-left{
						background-color: $vert-f;
						position: absolute;
						top: 0;
						height: 50%;
					}

					.with-row-un{
						height: 50%;
						float: left;
						position: relative;
						.vid-button-play{
							background-color: #000;
							position: absolute;
							width: 82px;
							height: 72px;
							top: 50%;
							left: 50%;
							transform: translate(-50%, -50%);
							@media screen and (max-width: 991px){

							}
							@media screen and (max-width: 767px){
								width: 72px;
								height: 62px;
							}
							@media screen and (max-width: 580px){
								width: 62px;
								height: 52px;
							}
							@media screen and (max-width: 420px){
								width: 52px;
								height: 42px;
							}
							&:after{
								background-image: url(../images/svg/play-service.svg);
								background-repeat: no-repeat;
								background-size: 100% 100%;
								background-position: center center;
								position: absolute;
								display: block;
								content: "";
								top: 50%;
								left: 50%;
								width: 17px;
								height: 27px;
								filter: invert(100%);
								transform: translate(-50%, -50%);
								@media screen and (max-width: 767px){
									
								}
								@media screen and (max-width: 580px){
									width: 12px;
									height: 22px;
								}
								@media screen and (max-width: 420px){
									
								}
							}
						}
						.int{

							.img{
								background-size: auto 100%;
								display: block;
								width: auto;
								height: 2.865vw;
								@media screen and (max-width: 767px){
									display: none;
								}
							}
							span{

							}
						}
						&#left{
							background-color: #1e1e1e;
							background-size: cover;
							background-repeat: no-repeat;
							width: 50%;
							height: 100%;
							z-index: 3;
							@media screen and (max-width: 991px){
								width: 60%;
							}
							@media screen and (max-width: 767px){
								width: 75%;
							}
							@media screen and (max-width: 480px){
								width: 100%;
							}

						}
						&#centre{
							background-color: #1e1e1e;
							background-image: url(../images/grid-photo-2.jpg);
							background-size: cover;
							background-repeat: no-repeat;
							background-position: left bottom;
							width: 25%;
							height: 50%;
							@media screen and (max-width: 991px){
								display: none;
							}

						}
						&#right{
							background: #fff;
							width: 25%;
							height: 50%;
							@media screen and (max-width: 480px){
								width: 50% !important;
								margin: 0 !important;
							}
						}
					}
					.with-row-deux{
						height: 50%;
						float: left;
						position: relative;
						&#centre{
							background-color: $vert-f;
							width: 25%;
							height: 50%;
							@media screen and (max-width: 991px){
								width: 25%;
							}
							@media screen and (max-width: 767px){
								width: 25%;
							}
							@media screen and (max-width: 480px){
								width: 50% !important;
								margin: 0 !important;
							}
						}
						&#right{
							background-color: #1e1e1e;
							background-image: url(../images/grid-photo-3.jpg);							
							background-size: cover;
							background-repeat: no-repeat;
							width: 25%;
							height: 50%;
						}
					}
				}
				&#row-trois{
					height: 12.761vw;
					@media screen and (max-width: 991px){
						height: 15.761vw;
					}
					@media screen and (max-width: 480px){
						height: 18.761vw;
					}
					.with-row{
						height: 100%;
						float: left;
						position: relative;
						.int{

							.img{
								background-size: auto 100%;
								display: block;
								width: auto;
								height: 2.865vw;
								filter: invert(100%);
								@media screen and (max-width: 767px){
									display: none;
								}
							}
							span{
								color: #fff;
							}
						}
						&#left{
							background-color: #000;
							width: 25%;
							margin: 0 0 0 25%;
							z-index: 3;
							@media screen and (max-width: 767px){
								width: 50%;
								margin: 0;
							}
						}
						&#centre{
							background-color: #1e1e1e;
							background-image: url(../images/grid-photo-4.jpg);
							background-size: cover;
							background-repeat: no-repeat;
							width: 25%;
							@media screen and (max-width: 767px){
								display: none;
							}
						}
						&#right{
							background-color: #1a1a1a;
							width: 25%;
							@media screen and (max-width: 767px){
								width: 50%;
							}
						}
					}
				}
			}
		}
	}
}

#realisations{

	#top{
		padding: 9.4vw 0 12vw 0;
		@media screen and (max-width: 767px){
			padding: 10.4vw 0 13vw 0;
		}
		.corps{

			.titre{

				h3{
					position: relative;
					color: #fff;
					font-size: 4.5vw;
					font-family: $barlow;
					font-weight: 800;
					z-index: 2;
					@media screen and (max-width: 991px){
						font-size: 5.5vw;
					}
					@media screen and (max-width: 767px){
						font-size: 50px;
					}
					@media screen and (max-width: 420px){
						font-size: 40px;
					}
				}
			}
		}
	}
	#bottom{
		background-color: #000;
		height: 32.709vw;
		@media screen and (max-width: 767px){
			height: auto;
			padding: 0 0 90px 0;
		}
		@media screen and (max-width: 580px){
			
		}
		.corps{
			.bou{
				position: absolute;
				bottom: 3.6vw;
				color: $vert-p;
				z-index: 9;
				@media screen and (max-width: 991px){
					bottom: -2vw;
				}
				@media screen and (max-width: 767px){
					bottom: -50px;
					left: 50%;
					transform: translate(-50%, 0);
					width: 70%;
				}
				@media screen and (max-width: 580px){
					width: 80%;
					text-align: center;
				}
				@media screen and (max-width: 420px){
					bottom: -60px;
				}
			}
			.world-section{
				position: absolute;
				margin: 0 0 0 5%;
				bottom: -3.4vw;
				line-height: 100%;
				z-index: -1;
				@media screen and (max-width: 767px){
					display: none;
				}
				span{
					color: rgba(1, 1, 1, 0.2);
					font-size: 6.95vw;
					font-family: $barlow;
					font-weight: 900;
				}
			}
			#cc-rea-carou{
				position: absolute;
				width: 100%;
				top: -8vw;
				height: 30.834vw;
				z-index: 2;
				@media screen and (max-width: 767px){
					position: inherit;
					height: auto;
				}
				.cc-rea-control{
					position: absolute;
					right: 0;
					bottom: 0;
					width: 12.5vw;
					height: calc(100% - 15.625vw);
					@media screen and (max-width: 767px){
						height: calc(20%);
						bottom: 14vw;
						width: 13.5vw;
					}
					@media screen and (max-width: 480px){
						height: calc(20%);
						bottom: 15vw;
						width: 15vw;
					}
					a{
						position: initial;
						top: initial;
						left: initial;
						right: initial;
						bottom: initial;
						display: inline-block;
						width: 100%;
						height: 50%;
						opacity: 1;

						&:first-child{
							.glyphicon{
								&:after{

									
								}
								&:hover{
									&:after{
										left: 60%;
									}
								}
							}
						}
						&:last-child{
							.glyphicon{
								&:after{

									transform: translate(-50%, -50%) rotate(180deg);
								}
								&:hover{
									&:after{
										left: 40%;
									}
								}
							}
						}
						.glyphicon{
							position: relative;
							display: block;
							width: 100%;
							height: 100%;
							top: initial;
							left: initial;
							right: initial;
							bottom: initial;
							margin: 0 !important;
							&:before{
								display: none;
							}
							&:after{
								background-image: url(../images/svg/arrow-slide.svg);
								background-size: 100% 100%;
								background-position: center center;
								position: absolute;
								display: block;
								content: "";
								top: 50%;
								left: 50%;
								width: 3.698vw;
								height: 1.875vw;
								transform: translate(-50%, -50%);
								transition: 0.4s;
								@media screen and (max-width: 767px){
									width: 5vw;
									height: 3vw;
								}
							}
						}
						.sr-only{
							display: none;
						}
					}
				}
				.carousel-indicators{
					top: 7.9vw;
					left: initial;
					right: calc(12.5vw - 30px);
					bottom: initial;
					width: initial;
					margin: 0;
					@media screen and (max-width: 991px){
						right: -5.046vw;
					}
					li{
						display: block;
						margin: 0;
						width: 12px;
						height: 12px;
						border: 1px solid #000;
						border-radius: initial;
						background-color: #000;
						@media screen and (max-width: 991px){
							border: 1px solid $vert-f;
							background-color: $vert-f;
						}
						& + li{
							margin: 12px 0 0 0;
						}
						&.active{
							background-color: transparent;
							cursor: initial;
						}
					}
				}
				.carousel-inner{
					height: 100%;
					overflow: visible;
					.item{
						height: 100%;
						.item-int{
							height: 100%;
							.present-img{
								background-color: #1e1e1e;
								background-repeat: no-repeat;
								background-size: cover;
								position: absolute;
								right: 12.5vw;
								bottom: -2.6vw;
								width: 40.365vw;
								height: 25.573vw;
								@media screen and (max-width: 991px){
									right: 0;
									width: calc(100% - 38.438vw);
								}
								@media screen and (max-width: 767px){
									display: none;
								}
							}
							.left{
								background-color: #fff;
								width: 38.438vw;
								height: 100%;
								float: left;
								@media screen and (max-width: 991px){
									height: auto;
								}
								@media screen and (max-width: 767px){
									width: 100%;
									height: auto;
									float: initial;
								}
								.left-int{
									padding: 3.35vw 0 0 3.6vw;
									@media screen and (max-width: 991px){
										padding: 3.35vw  0 3.35vw 3.6vw;
									}
									@media screen and (max-width: 767px){
										padding: 5.3vw  0 5.3vw 3.6vw;
									}
									.type{

										span{
											position: relative;
											color: $vert-p;
											font-size: 1.05vw;
											font-family: $barlow;
											font-weight: 500;
											text-transform: uppercase;
											letter-spacing: 0.2010em;
											margin: 0 0 1.4vw 0;
											display: block;
											@media screen and (max-width: 991px){
												font-size: 1.15vw;
											}
											@media screen and (max-width: 767px){
												font-size: 15px;
											}
											@media screen and (max-width: 480px){
												
											}
											.bar{

												background: $vert-p;
												position: absolute;
												display: block;
												content: "";
												width: 18.0523vw;
												height: 2px;
												top: 50%;
												right: 0;
												transform: translate(0, -50%);
											}
										}
									}
									.contenu{
										@media screen and (max-width: 991px){
											padding: 0 3.6vw 0 0;
										}
										@media screen and (max-width: 767px){
											padding: 0 12.5vw 0 0;
											margin: 0 0 4vw 0;
										}
										h3{
											font-size: 1.9vw;
											font-family: $barlow;
											font-weight: 800;
											max-width: 15.948vw;
											margin: 0 0 0.5vw 0;
											@media screen and (max-width: 991px){
												max-width: 100%;
												font-size: 2.3vw;
											}
											@media screen and (max-width: 767px){
												font-size: 23px;
											}
											@media screen and (max-width: 480px){
												
											}
										}
										h6{
											font-size: 1.1vw;
											font-family: $barlow;
											font-weight: 800;
											@media screen and (max-width: 1175px){
												font-size: 1.1vw;
											}
											@media screen and (max-width: 991px){
												font-size: 1.2vw;
											}
											@media screen and (max-width: 767px){
												font-size: 15px;
											}
											@media screen and (max-width: 480px){
												
											}
										}
										p{
											font-size: 1.1vw;
											font-family: $barlow;
											margin: 1.6vw 0 1.5vw 0;
											max-width: 17.7vw;
											@media screen and (max-width: 1175px){
												font-size: 1.05vw;
												max-width: 20.7vw;
											}
											@media screen and (max-width: 991px){
												max-width: 100%;
												font-size: 1.4vw;
											}
											@media screen and (max-width: 767px){
												font-size: 16px;
												line-height: 24px;
											}
											@media screen and (max-width: 480px){
												
											}
										}
									}
									.timing{
										margin: 0 0 1.5vw 0;
										@media screen and (max-width: 767px){
											margin: 0 0 3vw 0;
										}
										span{
											display: block;
											height: 2.084vw;
											font-size: 1.2vw;
											font-family: $barlow;
											letter-spacing: 0.05em;
											line-height: 2.084vw;
											@media screen and (max-width: 1175px){
												font-size: 1.4vw;
											}
											@media screen and (max-width: 991px){
												font-size: 1.3vw;
												height: 3vw;
												line-height: 3vw;
											}
											@media screen and (max-width: 767px){
												font-size: 16px;
												height: 26px;
												line-height: 26px;
											}
											@media screen and (max-width: 580px){
												
											}
											&:before{
												background-image: url(../images/svg/time.svg);
												background-size: 100% 100%;
												background-repeat: no-repeat;
												background-position: center center;
												position: relative;
												display: block;
												content: "";
												width: 2.084vw;
												height: 100%;
												float: left;
												margin: 0 1vw 0 0;

												@media screen and (max-width: 991px){
													width: 3vw;
												}
												@media screen and (max-width: 767px){
													width: 3.5vw;
												}
												@media screen and (max-width: 480px){
													width: 4vw;
												}
											}
										}
									}
									a{
										border: 1px solid $vert-p;
										padding: 1vw 1.1vw;
										display: inline-block;
										font-size: 0.85vw;
										font-family: $barlow;
										font-weight: 900;
										text-transform: uppercase;
										letter-spacing: 0.2010em;
										background-color: $vert-p;
										color: #fff;
										@media screen and (max-width: 1175px){
											font-size: 1.1vw;
										}
										@media screen and (max-width: 991px){
											font-size: 1.7vw;
										}
										@media screen and (max-width: 767px){
											font-size: 15px;
										}
										@media screen and (max-width: 480px){
											
										}
									}
								}
							}
							.right{
								width: calc(100% - 38.438vw);
								height: 100%;
								float: left;
								@media screen and (max-width: 767px){
									width: 100%;
									height: auto;
									float: initial;
								}
								.top{
									background: $vert-p;
									position: relative;
									height: 15.625vw;
									z-index: -1;
									@media screen and (max-width: 767px){
										height: auto;
									}
									.emplacement{
										position: absolute;
										top: 2.7vw;
										@media screen and (max-width: 767px){
											position: initial;
											top: initial;
											padding: 3.5vw 0;
										}
										span{
											display: block;
											height: 2.813vw;
											font-size: 1.9vw;
											font-family: $barlow;
											letter-spacing: 0.05em;
											padding: 0 0 0 40px;
											line-height: 2.813vw;
											@media screen and (max-width: 767px){
												font-size: 15px;
												height: 29px;
												line-height: 29px;
											}
											@media screen and (max-width: 580px){
												
											}
											&:before{
												background-image: url(../images/svg/emplacement.svg);
												background-size: 100% 100%;
												background-repeat: no-repeat;
												background-position: center center;
												position: relative;
												display: block;
												content: "";
												width: 2.032vw;
												height: 100%;
												float: left;
												margin: 0 1vw 0 0;
												@media screen and (max-width: 767px){
													width: 15px;
												}
											}
										}
									}
								}
								.bottom{
									height: calc(100% - 15.625vw);
								}
							}
						}
					}
				}
			}
		}
	}
}

#questions{
	z-index: 2;
	.corps{

		.contenu{
			padding: 8.85416vw 0 8.33334vw 0;
			@media screen and (max-width: 480px){
				padding: 13vw 0;
			}
			.tp{
				margin: 0 0 1.3vw 0;
			}
			a{
				color: #fff;
				font-size: 1.4vw;
				display: table;
				height: 1.615vw;
				line-height: 1.615vw;
				letter-spacing: 0.05em;
				@media screen and (max-width: 991px){
					height: 18px;
					line-height: 18px;
					font-size: 16px;
				}
				@media screen and (max-width: 580px){
					margin: 20px 0 0 0;
				}
				&:before{
					background-image: url(../images/svg/contact.svg);
					background-size: 100% 100%;
					background-repeat: no-repeat;
					background-position: center center;
					position: relative;
					display: block;
					content: "";
					width: 3.936vw;
					height: 100%;
					float: left;
					margin: 0 1vw 0 0;
					@media screen and (max-width: 991px){
						width: 5vw;
					}
					@media screen and (max-width: 767px){
						width: 6vw;
					}
					@media screen and (max-width: 480px){
						width: 7vw;
					}
				}
			}
		}
	}
}

// General
#pop-vid-services{
	background: #000;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 30;
	visibility: visible !important;
	iframe{
		position: absolute;
		display: block;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.close-vid{
		background-image: url(../images/svg/close.svg);
		background-size: 100% 100%;
		background-position: 50%;
		position: absolute;
		display: block;
		width: 36px;
		height: 36px;
		top: 100px;
		right: 100px;
		cursor: pointer;
		z-index: 22;
		@media screen and (max-width: 580px){
			right: initial;
			left: 50%;
			transform: translate(-50%, 0);
		}
	}
}
.panVid{
	background: #000;
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 30;
	visibility: visible !important;
	iframe{
		position: absolute;
		display: block;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
	.close-vid-pan{
		background-image: url(../images/svg/close.svg);
		background-size: 100% 100%;
		background-position: 50%;
		position: absolute;
		display: block;
		width: 36px;
		height: 36px;
		top: 100px;
		right: 100px;
		cursor: pointer;
		z-index: 22;
		@media screen and (max-width: 580px){
			right: initial;
			left: 50%;
			transform: translate(-50%, 0);
		}
	}
}